export const TTL = 86400;

export const SALE_ORDER_DETAILS_SECTIONS = {
  SELLER_ORDER_DETAILS: { id: 'SELLER_ORDER_DETAILS', title: 'Seller Order Details' },
  BUYER_ORDER_DETAILS: { id: 'BUYER_ORDER_DETAILS', title: 'Buyer Order Details' },
  LOGISTICS_DETAILS: { id: 'LOGISTICS_DETAILS', title: 'Logistics Details' },
  TRUCK_TRACKING: { id: 'TRUCK_TRACKING', title: 'Truck Tracking' },
  DISPATCHED_DETAILS: { id: 'DISPATCHED_DETAILS', title: 'Dispatched Details' },
  ACCOUNTS_DOCUMENTS: { id: 'ACCOUNTS_DOCUMENTS', title: 'Accounts Documents' },
  TRANSPORTER_DOCUMENT: { id: 'TRANSPORTER_DOCUMENT', title: 'Transporter Documents' },
  NOTE_FROM_BUYER: { id: 'NOTE_FROM_BUYER', title: 'Note from Buyer/Seller' },
  SELLER_DOCUMENTS: { id: 'SELLER_DOCUMENTS', title: 'Seller Documents' },
  DELIVERY_DETAILS: { id: 'DELIVERY_DETAILS', title: 'Delivery Details' },
  PICKUP_DETAILS: { id: 'PICKUP_DETAILS', title: 'Pickup Details' },
  PASS_DOCS_TO_SELLER: { id: 'PASS_DOCS_TO_SELLER', title: 'Pass Docs to Seller' },
};

// sequence - it is used to show saleorder details section in sale-order details page
export const SALE_ORDER_STATUS = {
  PLACED: {
    id: 'PLACED',
    name: 'Placed',
    colors: '#f3f5ff',
    info: '{placedtxt}',
    textColor: '#3044ff',
    sequence: [SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id],
    activeStep: 0,
    isOpenStatus: [SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id],
  },
  CANCELLED: {
    id: 'CANCELLED',
    name: 'Cancelled',
    colors: '#fff2f2',
    info: '{reason}',
    textColor: '#e94340',
    sequence: [SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id],
    activeStep: 0,
  },
  RETURN: {
    id: 'RETURN',
    name: 'Return',
    colors: '#fff2f2',
    info: '{reason}',
    textColor: '#e94340',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
    activeStep: 6,
  },
  RESELL: {
    id: 'RESELL',
    name: 'Resell',
    colors: '#fff2f2',
    info: '{reason}',
    textColor: '#e94340',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
    activeStep: 6,
  },
  PENDING_AGGREGATOR_APPROVAL: {
    id: 'PENDING_AGGREGATOR_APPROVAL',
    name: 'Pending Approval',
    colors: '#fff7f0',
    info: 'No response since {value}',
    textColor: '#eb8116',
  },
  APPROVED: {
    id: 'APPROVED',
    name: 'Approved',
    colors: '#e2f5f0',
    info: 'Pending Truck Assignment',
    textColor: '#00c77e',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
    ],
    activeStep: 1,
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
    ],
  },

  TRUCK_ASSIGNED: {
    id: 'TRUCK_ASSIGNED',
    name: 'Truck Assigned',
    colors: '#f2eaff',
    info: 'Pending pickup from aggregator',
    textColor: '#5e0be0',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
    activeStep: 2,
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
  },
  MATERIAL_LOADED: {
    id: 'MATERIAL_LOADED',
    name: 'Material Loaded',
    colors: '#fff7ee',
    info: 'Pending payment from accounts',
    textColor: '#9e7540',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
    ],
    activeStep: 3,
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
    ],
  },
  ORDER_VERIFIED: {
    id: 'ORDER_VERIFIED',
    name: 'Order Verified',
    colors: '#fadaff',
    info: 'Pending payment from accounts',
    textColor: '#91409e',
    sequence: [
      // SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
    ],
    activeStep: 4,
    isOpenStatus: [
      // SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
    ],
  },
  PAYMENT_DONE: {
    id: 'PAYMENT_DONE',
    name: 'Payment Done',
    colors: '#ecffe9',
    info: 'Payment made to aggregator',
    textColor: '#3aa12b',
  },
  DISPATCHED: {
    id: 'DISPATCHED',
    name: 'Dispatched',
    colors: '#e8f9ff',
    info: 'Material sent to recycler location',
    textColor: '#28a1cd',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
    ],
    activeStep: 5,
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
  },
  RECEIVED_BY_RECYCLER: {
    id: 'RECEIVED_BY_RECYCLER',
    name: 'Delivered',
    colors: '#ffecf2',
    info: 'Awaiting for Quality Report',
    textColor: '#c86b85',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
    ],
    activeStep: 6,
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
  },
  QC_DONE: {
    id: 'QC_DONE',
    name: 'QC Done ',
    colors: '#eefffe',
    info: 'Awaiting payment from recycler',
    textColor: '#307672',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
    activeStep: 7,
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
    ],
  },
  COMPLETED: {
    id: 'COMPLETED',
    name: 'Completed',
    colors: '#eafffd',
    info: 'Received full payment from recycler',
    textColor: '#00b8a9',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
    activeStep: 8,
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
    ],
  },
  PARTIAL_PAYMENT_RECEIVED: {
    id: 'PARTIAL_PAYMENT_RECEIVED',
    name: 'Partial Payment Received',
    colors: '#fdfde9',
    info: 'Payment yet to receive from recycler',
    textColor: '#7d7d21',
  },
  DOCUMENT_PENDING: {
    id: 'DOCUMENT_PENDING',
    name: 'Documents Pending',
    colors: '#fdfde9',
    info: 'Documents yet to get uploaded',
    textColor: '#7d7d21',
  },
  ORDER_COMPLETED: {
    id: 'ORDER_COMPLETED',
    name: 'Order Completed',
    colors: '#eafffd',
    info: 'Received full payment from recycler',
    textColor: '#00b8a9',
    sequence: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
      SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
      SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
    ],
    isOpenStatus: [
      SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
      // SALE_ORDER_DETAILS_SECTIONS.NOTE_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
      SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
      SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
      SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    ],
  },
};

export const EXCLUDE_ORDER_STATUS = [
  'PARTIAL_PAYMENT_RECEIVED',
  'MATERIAL_PAYMENT_DONE',
  'PARTIAL_GST_PAYMENT',
  'GST_PAYMENT_DONE',
  'AGGREGATOR_PAYMENT_DONE',
  'DOCUMENT_PENDING',
  'PARTIAL_PAYMENT_RECEIVED',
  'PARTIAL_MATERIAL_PAYMENT',
  'PAYMENT_DONE',
  'PENDING_AGGREGATOR_APPROVAL',
  'ASSESSMENT_IN_PROGRESS',
  'ASSESSMENT_DONE',
];

const GST_KYC_STATUS = {
  COMPLETE: {
    id: 'COMPLETE',
    name: 'Complete',
    colors: '#ECFDF3',
    textColor: '#067647',
    borderColors: '#ABEFC6',
  },
  PENDING: {
    id: 'PENDING',
    name: 'Pending',
    colors: '#FFFAEB',
    textColor: '#B54708',
    borderColors: '#FEDF89',
  },
};

const AGGREGATOR_STATUS = {
  NONE: {
    id: 'NONE',
    name: 'NA',
    colors: '#FFFFFF',
    textColor: '#000000',
  },
  ONBOARDED: {
    id: 'ONBOARDED',
    name: 'Onboarded',
    colors: '#F7F5FF',
    textColor: '#83A4FF',
  },
  NEW: {
    id: 'NEW',
    name: 'New',
    colors: '#DDE6FD',
    textColor: '#3D5FBE',
  },
  ACQUIRED: {
    id: 'ACQUIRED',
    name: 'Acquired',
    colors: '#DCEDCF',
    textColor: '#3D6E19',
  },
  ACTIVE: {
    id: 'ACTIVE',
    name: 'Active',
    colors: '#E5FFD1',
    textColor: '#73B343',
  },
  POTENTIAL_CHURN: {
    id: 'POTENTIAL_CHURN',
    name: 'Potential Churn',
    colors: '#FFF4EE',
    textColor: '#FFA26C',
  },
  CHURNED: {
    id: 'CHURNED',
    name: 'Churned',
    colors: '#FFF5F5',
    textColor: '#FF6969',
  },
  REACTIVATED: {
    id: 'REACTIVATED',
    name: 'Reactivated',
    colors: '#FFF3D8',
    textColor: '#E3B142',
  },
  ACQUIRED_BUT_POTENTIAL_CHURN: {
    id: 'ACQUIRED_BUT_POTENTIAL_CHURN',
    name: 'Acquired but Potential Churn',
    colors: '#FFEDF3',
    textColor: '#CC7392',
  },
  DEACTIVATED: {
    id: 'DEACTIVATED',
    name: 'Deactivated',
    colors: '#F3F3F3',
    textColor: '#B8B8B8',
  },
  REACQUIRED: {
    id: 'REACQUIRED',
    name: 'Reacquired',
    colors: '#E5FFD1',
    textColor: '#73B343',
  },
};

const AGGREGATOR_TYPES = [
  {
    key: 'Aggregator',
    value: 'AGGREGATOR',
  },
  {
    key: 'App Leads',
    value: 'APP-LEADS',
  },
];

const RECONCILATION_STATUS = {
  MATCHED: {
    id: 'MATCHED',
    name: 'Matched',
    textColor: '#73B343',
  },
  MISMATCH: {
    id: 'MISMATCH',
    name: 'MisMatch',
    textColor: '#D66960',
  },
  MISSING_IN_BOOKS: {
    id: 'MISSING_IN_BOOKS',
    name: 'Missing In Books',
    textColor: '#5F7BD8',
  },
  MISSING_IN_2A: {
    id: 'MISSING_IN_2A',
    name: 'Missing In 2A',
    textColor: '#5F7BD8',
  },
  ACCEPTED: {
    id: 'ACCEPTED',
    name: 'Accepted',
    textColor: '#73B343',
  },
  LINKED: {
    id: 'LINKED',
    name: 'Linked',
    textColor: '#73B343',
  },
};

// Giving permissions for multiple documents upload
const ORDER_STATUS = {
  PLACED: 'PLACED',
  CANCELLED: 'CANCELLED',
  RETURN: 'RETURN',
  PENDING_AGGREGATOR_APPROVAL: 'PENDING_AGGREGATOR_APPROVAL',
  APPROVED: 'APPROVED',
  APPROVED_BY_AGGREGATOR: 'APPROVED_BY_AGGREGATOR',
  TRUCK_ASSIGNED: 'TRUCK_ASSIGNED',
  COMPLETED: 'COMPLETED',
  ASSESSMENT_DONE: 'ASSESSMENT_DONE',
  MATERIAL_LOADED: 'MATERIAL_LOADED',
  ORDER_VERIFIED: 'ORDER_VERIFIED',
  // PAYMENT_DONE: 'PAYMENT_DONE',
  DISPATCHED: 'DISPATCHED',
  RECEIVED_BY_RECYCLER: 'RECEIVED_BY_RECYCLER',
  QC_DONE: 'QC_DONE',
  PARTIAL_PAYMENT_RECEIVED: 'PARTIAL_PAYMENT_RECEIVED',
  DOCUMENT_PENDING: 'DOCUMENT_PENDING',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  RESELL: 'RESELL',
};

const PO_STATUS = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  PAYMENT_IN_PROGRESS: 'PAYMENT_IN_PROGRESS',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  IN_PROGRESS: 'IN_PROGRESS',
  FULFILLED: 'FULFILLED',
};

export const STATIC_UNIT = 'KG';

export const PURCHASE_ORDER_STATUS = {
  // DRAFT: {
  //   id: 'DRAFT',
  //   name: 'Draft',
  //   colors: '#e1e1e1',
  //   textColor: '#585858',
  //   borderColor: '#FECDCA',
  // },
  // IN_REVIEW: {
  //   id: 'IN_REVIEW',
  //   name: 'In Review',
  //   colors: '#f6f0ff',
  //   textColor: '#6200ee',
  //   borderColor: '#FECDCA',
  // },
  APPROVED: {
    id: 'APPROVED',
    name: 'Approved',
    colors: '#EFF8FF',
    textColor: '#175CD3',
    borderColor: '#B2DDFF',
  },
  // PAYMENT_IN_PROGRESS: {
  //   id: 'PAYMENT_IN_PROGRESS',
  //   name: 'Payment In Progress',
  //   colors: '#f4ffd4',
  //   textColor: '#90c207',
  //   borderColor: '#FECDCA',
  // },
  // OPEN: {
  //   id: 'OPEN',
  //   name: 'Open',
  //   colors: '#ddf0ff',
  //   textColor: '#4aa3e8',
  //   borderColor: '#FECDCA',
  // },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    name: 'In Progress',
    colors: '#FFFAEB',
    textColor: '#B54708',
    borderColor: '#FEDF89',
  },

  FULFILLED: {
    id: 'FULFILLED',
    name: 'Completed',
    colors: '#ECFDF3',
    textColor: '#067647',
    borderColor: '#ABEFC6',
  },
  CLOSED: {
    id: 'CLOSED',
    name: 'Closed',
    colors: '#FEF3F2',
    textColor: '#B42318',
    borderColor: '#FECDCA',
  },
  COMPLETED: {
    id: 'COMPLETED',
    name: 'Completed',
    colors: '#ECFDF3',
    textColor: '#067647',
    borderColor: '#ABEFC6',
  },
};

export const CREATE_LABEL_LOOKUP = {
  0: '',
  1: 'Weak',
  2: 'Weak',
  3: 'Medium',
  4: 'Medium',
  5: 'Strong',
};

export const PROGRESS_COLOR_LOOKUP = {
  0: '#828282',
  1: '#EA1111',
  2: '#EA1111',
  3: '#52ABD0',
  4: '#52ABD0',
  5: '#73B343',
};

export const PROGRESS_BAR_STATUSES = [
  {
    status: 'Order Placed',
    title: 'Order Placed',
    toStatus: 'PLACED',
    date: null,
  },
  {
    status: 'Approved',
    title: 'Approved',
    toStatus: 'APPROVED',
    date: null,
  },

  {
    status: 'Truck Assigned',
    title: 'Truck Assigned',
    toStatus: 'TRUCK_ASSIGNED',
    date: null,
  },
  {
    status: 'Material Loaded',
    title: 'Material Loaded',
    toStatus: 'MATERIAL_LOADED',
    date: null,
  },

  {
    status: 'Order Verified',
    title: 'Order Verified',
    toStatus: 'ORDER_VERIFIED',
    date: null,
  },

  {
    status: 'Dispatched',
    title: 'Dispatched',
    toStatus: 'DISPATCHED',
    date: null,
  },
  {
    status: 'Delivered',
    title: 'Delivered',
    toStatus: 'RECEIVED_BY_RECYCLER',
    date: null,
  },
  {
    status: 'Return',
    title: 'Return',
    toStatus: 'RETURN',
    date: null,
  },
  {
    status: 'Resell',
    title: 'Resell',
    toStatus: 'RESELL',
    date: null,
  },
  {
    status: 'QC Done',
    title: 'QC Done',
    toStatus: 'QC_DONE',
    date: null,
  },
  {
    status: 'Completed',
    title: 'Completed',
    toStatus: 'COMPLETED',
    date: null,
  },
];
export const PROGRESS_BAR_EXCLUDED_STATUS = [
  'ASSESSMENT_IN_PROGRESS',
  'ASSESSMENT_DONE',
  'PARTIAL_MATERIAL_PAYMENT',
  'GST_PAYMENT_DONE',
  'AGGREGATOR_PAYMENT_DONE',
  'MATERIAL_PAYMENT_DONE',
];
const SERVICE = {
  CORE: 'CORE',
  STAKEHOLDER: 'STAKEHOLDER',
  MARKETPLACE: 'MARKETPLACE',
};
const ASSESSMENT_STATUS = {
  PENDING: 'PENDING',
  DRAFT: 'DRAFT',
  ASSESSED: 'ASSESSED',
  FINALIZED: 'FINALIZED',
};

const ROLES = {
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
  // CONTROL_ROOM: 'ROLE_MP_CONTROL_ROOM',
  KAM: 'ROLE_KEY_ACCOUNT_MANAGER',
  ACCOUNTS: 'ROLE_MARKETPLACE_ACCOUNTS',
  BUSINESS_HEAD: 'ROLE_MARKETPLACE_BUSINESS',
  UNIVERSAL_USER: 'ROLE_UNIVERSAL_USER',

  // PLATFORM ADMIN ROLE
  PLATFORM_ADMIN: 'ROLE_PLATFORM_ADMIN',
};

const LISTING_ORDER_REJECT_REASONS = [
  { id: 1, reason: 'Invalid - Wrong Address' },
  { id: 2, reason: 'Invalid - Wrong Item selected' },
  { id: 3, reason: 'Invalid - Loose Material seller' },
  { id: 4, reason: 'Invalid - Wrong Seller Name' },
  { id: 5, reason: 'Invalid - Duplicate listing' },
  { id: 6, reason: 'Sold Offline' },
  { id: 7, reason: 'Seller Not Responding' },
  { id: 8, reason: 'Test' },
  { id: 9, reason: 'No Material' },
  { id: 10, reason: 'Unrealistic Price' },
  { id: 11, reason: 'Future listing' },
  { id: 12, reason: 'Internal listing' },
  { id: 13, reason: 'Sold OutSide' },
  { id: 14, reason: 'Other' },
];
const PURCHASE_ORDER_REJECT_REASONS = [
  { id: 1, reason: 'Due to rate fluctuation in price day to day' },
  { id: 2, reason: 'Delay in placing the vehicle' },
  { id: 3, reason: 'Testing of the app' },
  { id: 4, reason: 'Negotiating after placing the order' },
  { id: 5, reason: 'Requirement of material from a particular area only' },
  { id: 6, reason: ' unavailable manpower during the loading time.& climate changes' },
  { id: 7, reason: 'Other' },
];
const AGGREGATOR_ORDER_SCHEDULED_REASONS = [
  { id: 1, reason: 'Material not available' },
  { id: 2, reason: 'Seller not reachable' },
  { id: 3, reason: 'Other' },
];
const SALE_ORDER_REJECT_REASONS = [
  { id: 1, reason: 'This is a fake order' },
  { id: 2, reason: 'The aggregator was only testing the app' },
  { id: 3, reason: 'Material sold to other recyclers' },
  { id: 4, reason: 'Material sold to a trader' },
  { id: 5, reason: 'Quality of the material is not good' },
  {
    id: 6,
    reason: `The buyer canceled the order due to a delay
    in dispatch of the consignment after n days`,
  },
  { id: 7, reason: 'The buyer canceled the order because the market rates have fallen' },
  { id: 8, reason: 'The seller demanded a higher price and canceled the order' },
  { id: 9, reason: 'Control Team canceled the order-Buyer payment credit issues' },
  { id: 10, reason: 'Unable to place the truck in 3 days within our cost' },
  { id: 11, reason: 'Due to vehicle unavailability' },
  { id: 12, reason: 'Seller delayed in providing the labor for material loading' },
  { id: 13, reason: 'Seller wants 100% payment & GST amount in before GSTR1 filing' },
  { id: 14, reason: 'Order assigned to the wrong PO' },
  { id: 15, reason: 'The buyer was testing the app' },
  { id: 16, reason: 'Sold Outside' },
  { id: 17, reason: 'Other' },
];

const MATERIAL_REASONS = [
  { id: 1, reason: 'Vehicles previous load unloading issue' },
  { id: 2, reason: 'Driver behavioral issue' },
  { id: 3, reason: 'Vehicle break down' },
  { id: 4, reason: 'No entry time' },
  { id: 5, reason: 'Labour not available' },
  { id: 6, reason: 'Sufficient material not available' },
  { id: 6, reason: 'Quality issues with material' },
  { id: 7, reason: 'Other' },
];
const SALE_ORDER_TRUCK_DELAY_REASONS = [
  { id: 1, reason: 'Truck not available at our price' },
  { id: 2, reason: 'New route and no transporter was available' },
  { id: 3, reason: 'Huge demand in the market' },
  { id: 4, reason: 'Return load truck not available for the destination' },
  { id: 5, reason: 'Local Festival' },
  { id: 6, reason: 'High demand for Containers during rains' },
  { id: 7, reason: 'Other' },
];
const GEO_PREFERENCE_LIST = [
  { id: 'EXCLUDE', name: 'Inclusion' },
  { id: 'INCLUDE', name: 'Exclusion' },
];

const INPUT_MASK = {
  qualityParam: {
    prefix: '',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 3,
    suffix: '', // This will put the dollar sign at the end, with a space.
  },
  quantityParam: {
    prefix: '',
    allowDecimal: true,
    decimalLimit: 3,
    integerLimit: 9,
    suffix: '', // This will put the dollar sign at the end, with a space.
  },
};

const AUTHORITY = {
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
  CONTROL_ROOM: 'ROLE_MP_CONTROL_ROOM',
  KEY_ACCOUNT_MANAGER: 'ROLE_KEY_ACCOUNT_MANAGER',
  ACCOUNTS: 'ROLE_MARKETPLACE_ACCOUNTS',
  BUSINESS_HEAD: 'ROLE_MARKETPLACE_BUSINESS',
  UNIVERSAL_USER: 'ROLE_UNIVERSAL_USER',
  FULFILMENT_LEAD: 'ROLE_FULFILMENT_LEAD',
  COO: 'ROLE_COO',
  ROLE_ACCOUNTS_RECEIVABLE: 'ROLE_ACCOUNTS_RECEIVABLE',
  ROLE_ACCOUNTS_RECEIVABLE_E_WASTE: 'ROLE_ACCOUNTS_RECEIVABLE_E_WASTE',
  ROLE_ACCOUNTS_RECEIVABLE_METAL: 'ROLE_ACCOUNTS_RECEIVABLE_METAL',
  ROLE_ACCOUNTS_RECEIVABLE_PAPER: 'ROLE_ACCOUNTS_RECEIVABLE_PAPER',
  ROLE_ACCOUNTS_RECEIVABLE_PLASTIC: 'ROLE_ACCOUNTS_RECEIVABLE_PLASTIC',
  FINANCE_HEAD: 'ROLE_FINANCE_HEAD',
  ROLE_THIRD_PARTY: 'ROLE_THIRD_PARTY',
  ROLE_COMPLIANCE_ADMIN: 'ROLE_COMPLIANCE_ADMIN',
  ROLE_RSM: 'ROLE_RSM',
  ROLE_SPOC: 'ROLE_SPOC',
  ROLE_FULFILMENT_USER: 'ROLE_FULFILMENT_USER',
  ROLE_FIELD_EXECUTIVE: 'ROLE_FIELD_EXECUTIVE',
  ROLE_BUSINESS_EXECUTIVE: 'ROLE_BUSINESS_EXECUTIVE',
  ROLE_BUSINESS_MANAGER: 'ROLE_BUSINESS_MANAGER',
  ROLE_BUYER_BUSINESS: 'ROLE_BUYER_BUSINESS',
};

const gstCheckDate = '2021-10-01';

const PAGE_CONFIG = {
  size: 20,
};

const MOBILE_NUMBER_REGEX = '^[6789][0-9]{9}$';
const MAX_EIGHT_NUMBER_REGEX = '^([0-9]{4}|[0-9]{6}|[0-9]{8})$';
const EMAIL_REGEX = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$';
export const GSTIN_REGEX = '\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$';
export const HOLDER_NAME_REGEX = "^[a-zA-Z ']*$";
export const BANK_NAME_REGEX = "^[a-zA-Z-& ']*$";
export const BRANCH_NAME_REGEX = "^[a-zA-Z0-9-&, ']*$";
export const ACCOUNT_NUMBER_REGEX = '[0-9]$';
export const IFSC_REGEX = '^[ ]*[A-Za-z]{4}0[A-Z0-9a-z]{6}[ ]*$';

export const EMAIL_REGEX_RECYKAL = '^[a-zA-Z0-9._-]+@recykal+\\.[a-zA-Z]{2,6}$';
const ALPHA_NUMERIC = '^[a-zA-Z0-9]*$';
export const CAPITAL_SPLIT_REGEX = /(?=[A-Z])/;
export const NAME_REGEX = '^[a-zA-Z ]*$';
export const PINCODE_REGEX = '^[1-9]{1}[0-9]{2}[0-9]{3}$';
export const UPPERCASE_REGEX = ['[A-Z]'];
export const LOWERCASE_REGEX = ['[a-z]'];
export const NUMBER_REGEX = ['[0-9]'];
export const SPECIALCHARACTERS_REGEX = ['\\W'];
export const PAN_NUMBER_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

export const ITEMISSERVICE = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const GSTTAXRATES = [
  { id: 0, name: '0' },
  { id: 0.1, name: '0.1' },
  { id: 0.25, name: '0.25' },
  { id: 0.5, name: '0.5' },
  { id: 1, name: '1' },
  { id: 1.5, name: '1.5' },
  { id: 3, name: '3' },
  { id: 5, name: '5' },
  { id: 7.5, name: '7.5' },
  { id: 12, name: '12' },
  { id: 18, name: '18' },
  { id: 28, name: '28' },
];

export const AuthViews = {
  RESET_PASSWORD: '/views/ResetPasswordPage/RESET_PASSWORD',
  RESET_PASSWORD_FROM_LINK: '/views/ResetPasswordPage/RESET_PASSWORD_FROM_LINK',
  RESET_PASSWORD_SUCCESS: '/views/ResetPasswordPage/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FROM_LINK_SUCCESS: '/views/ResetPasswordPage/RESET_PASSWORD_FROM_LINK_SUCCESS',
};

const DOC_TYPES = {
  TRUCK_FRONT_PHOTO: 'TRUCK_FRONT_PHOTO',
  TRUCK_BACK_PHOTO: 'TRUCK_BACK_PHOTO',
  EMPTY_WEIGHTBRIDGE: 'EMPTY_WEIGHTBRIDGE',
  FULL_WEIGHBRIDGE: 'FULL_WEIGHBRIDGE',
  SELLER_INVOICE: 'SELLER_INVOICE',
  LORRY_RECEIPT: 'LORRY_RECEIPT',
  TRANSPORTER_INVOICE: 'TRANSPORTER_INVOICE',
  RECYCLER_WEIGHBRIDGE_BILL: 'RECYCLER_WEIGHBRIDGE_BILL',
  DC_ACKNOWLEDGEMENT: 'DC_ACKNOWLEDGEMENT',
  QC_DONE: 'QC_DONE',
  RECYCLER_PAYMENT_ADVICE: 'RECYCLER_PAYMENT_ADVICE',
  DEBIT_NOTE: 'DEBIT_NOTE',
  SALES_INVOICE: 'SALES_INVOICE',
  SALES_EWAY_BILL: 'SALES_EWAY_BILL',
  SELLER_PAYMENT_ADVICE: 'SELLER_PAYMENT_ADVICE',
  TRANSPORTER_PAYMENT_ADVICE: 'TRANSPORTER_PAYMENT_ADVICE',
  QC_REPORT_SELLER: 'QC_REPORT_SELLER',
  WEIGHBRIDGE_SELLER: ' WEIGHBRIDGE_SELLER',
  MATERIAL_PHOTOS_SELLER: 'MATERIAL_PHOTOS_SELLER',
  DEBIT_NOTE_SELLER: 'DEBIT_NOTE_SELLER',
  SELLER_EWAY_BILL: 'SELLER_EWAY_BILL',
  VEHICLE_DATA_ISSUE: 'VEHICLE_DATA_ISSUE',
  WEIGHBRIDGE_DATA_ISSUE: 'WEIGHBRIDGE_DATA_ISSUE',
  INVOICE_DATA_ISSUE: 'INVOICE_DATA_ISSUE',
};

const RECYCLER_DOCUMENTS = [
  {
    title: 'Buyer Weighbridge bill',
    type: 'RECYCLER_WEIGHBRIDGE_BILL',
    status: 'DISPATCHED',
    isDisabled: 'true',
  },
  {
    title: 'Buyer QC Report',
    type: 'QC_DONE',
    status: 'DISPATCHED',
    isDisabled: 'true',
  },
  {
    title: 'Debit Note',
    type: 'DEBIT_NOTE',
    status: 'ORDER_VERIFIED',
    isDisabled: 'true',
  },
  {
    title: 'Seller LR Copy',
    type: 'LR_COPY',
    status: 'DISPATCHED',
    isDisabled: 'true',
  },
  {
    title: 'GRN/Others',
    type: 'GRN',
    status: 'DISPATCHED',
    isDisabled: 'true',
  },
  {
    title: 'Transporter LR Copy',
    type: 'LORRY_RECEIPT',
    status: 'DISPATCHED',
    isDisabled: 'true',
  },
];

const SOURCE_OF_DOCUMENTS = [
  {
    id: 'QC_DONE',
  },
  {
    id: 'RECYCLER_WEIGHBRIDGE_BILL',
  },
  { id: 'DC_ACKNOWLEDGEMENT' },
  // { id: 'DEBIT_NOTE' },
];

const DOCUMENTS_SOURCE = [
  {
    id: 'QC_DONE',
  },
  {
    id: 'RECYCLER_WEIGHBRIDGE_BILL',
  },
  { id: 'SELLER_MATERIAL_PHOTOS' },
  { id: 'DC_ACKNOWLEDGEMENT' },
  { id: 'DEBIT_NOTE' },
  { id: 'SELLER_CREDIT_NOTE' },
  { id: 'SELLER_QC_REPORT' },
  { id: 'SELLER_WEIGHTBRIDGE' },
  { id: 'SELLER_DEBIT_NOTE' },
];

const USER_UPLOADING_QC_REPORT = [
  { email: 'jaswinder.singh@recykal.com' },
  { email: 'ajay.lattupally@recykal.com' },
  { email: 'induja.malyala@recykal.com' },
  { email: 'unnikrishnan.k@recykal.com' },
  { email: 'vijaya.gongati@recykal.com' },
  {
    email: 'gagan.namuru@recykal.com',
  },
];

const USER_UPLOADING_DEBIT_NOTE = [
  { email: 'ajay.lattupally@recykal.com' },
  { email: 'unnikrishnan.k@recykal.com' },
  { email: 'vijaya.gongati@recykal.com' },
  { email: 'kajal.sontakke@recykal.com' },
  { email: 'sindhu.mallipeddi@recykal.com' },
  { email: 'jaswinder.singh@recykal.com' },
];

const USER_UPLOADING_NOTE_TO_BUYER = [
  {
    email: 'koteswara.kuruguntla@recykal.com',
  },
  {
    email: 'saikrishna.e@recykal.com',
  },
  {
    email: 'gagan.namuru@recykal.com',
  },
  {
    email: 'jiban.panda@recykal.com',
  },
  { email: 'karthik.namavarapu@recykal.com' },
  { email: 'sindhu.mallipeddi@recykal.com' },
  { email: 'jaswinder.singh@recykal.com' },
];

const AGGREGATOR_DOCUMENTS_SALEORDER = [
  {
    title: 'Truck Front Photo',
    type: 'TRUCK_FRONT_PHOTO',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Truck Back Photo',
    type: 'TRUCK_BACK_PHOTO',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Empty Weighbridge Bill',
    type: 'EMPTY_WEIGHTBRIDGE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Full Weighbridge Bill',
    type: 'FULL_WEIGHBRIDGE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Seller Invoice',
    type: 'SELLER_INVOICE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Seller E-way Bill',
    type: 'SELLER_EWAY_BILL',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },

  // {
  //   title: 'EwayBill',
  //   type: 'EWAY_BILL',
  //   status: 'TRUCK_ASSIGNED',
  //   isDisabled: 'true',
  // },
  //  {
  //   title: 'Aggregator Payment Advice',
  //   type: 'SELLER_PAYMENT_ADVICE',
  //   status: 'MATERIAL_LOADED',
  //   isDisabled: 'true',
  // },
];
const AGGREGATOR_DOCUMENTS = [
  {
    title: 'Truck Front Photo',
    type: 'TRUCK_FRONT_PHOTO',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Truck Back Photo',
    type: 'TRUCK_BACK_PHOTO',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Empty Weighbridge Bill',
    type: 'EMPTY_WEIGHTBRIDGE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Full Weighbridge Bill',
    type: 'FULL_WEIGHBRIDGE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },
  {
    title: 'Seller Invoice',
    type: 'SELLER_INVOICE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
  },

  // {
  //   title: 'EwayBill',
  //   type: 'EWAY_BILL',
  //   status: 'TRUCK_ASSIGNED',
  //   isDisabled: 'true',
  // },
  {
    title: 'Seller Payment Advice',
    type: 'SELLER_PAYMENT_ADVICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
  },
];

const TRANSPORTER_DOCUMENTS_ACCOUNTS = [
  {
    title: 'LR Copy',
    type: 'LORRY_RECEIPT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'QC_DONE',
  },
  {
    title: 'Transporter Invoice',
    type: 'TRANSPORTER_INVOICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'MATERIAL_LOADED',
  },
  {
    title: 'Transporter Payment Advice',
    type: 'TRANSPORTER_PAYMENT_ADVICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'MATERIAL_LOADED',
  },
];

const TRANSPORTER_DOCUMENTS = [
  {
    title: 'LR Copy',
    type: 'LORRY_RECEIPT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  {
    title: 'Transporter Invoice',
    type: 'TRANSPORTER_INVOICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'MATERIAL_LOADED',
  },
  {
    title: 'Proof of Payment',
    type: 'PROOF_OF_PAYMENT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'MATERIAL_LOADED',
  },
  // {
  //   title: 'Transporter Payment Advice',
  //   type: 'TRANSPORTER_PAYMENT_ADVICE',
  //   status: 'MATERIAL_LOADED',
  //   isDisabled: 'true',
  //   acc_status: 'MATERIAL_LOADED',
  // },
];
const DISPATCH_DOCUMENTS = [
  {
    title: 'Sales Invoice',
    type: 'SALES_INVOICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  {
    title: 'Sales E-Way Bill',
    type: 'SALES_EWAY_BILL',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },

  {
    title: 'Transporter Payment Advice',
    type: 'TRANSPORTER_PAYMENT_ADVICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'MATERIAL_LOADED',
  },
];

const ACCOUNTS_DOCUMENTS = [
  {
    title: 'Sales Invoice',
    type: 'SALES_INVOICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  {
    title: 'Sales E-Way Bill',
    type: 'SALES_EWAY_BILL',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  // {
  //   title: 'Seller E-Way Bill',
  //   type: 'SELLER_UPLODED_E_WAY',
  //   status: 'MATERIAL_LOADED',
  //   isDisabled: 'true',
  //   acc_status: 'PAYMENT_DONE',
  // },
  {
    title: 'Seller Payment Advice',
    type: 'SELLER_PAYMENT_ADVICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'ORDER_VERIFIED',
  },
  {
    title: 'Transporter Payment Advice',
    type: 'TRANSPORTER_PAYMENT_ADVICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'MATERIAL_LOADED',
  },
  {
    title: 'Customer Debit Note',
    type: 'CUSTOMER_DEBIT_NOTE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'MATERIAL_LOADED',
  },
  {
    title: 'Seller Bill',
    type: 'PO_BILL_DOC',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'ORDER_VERIFIED',
  },
  {
    title: 'Transporter Bill',
    type: 'TRANSPORTER_BILL_DOC',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'ORDER_VERIFIED',
  },

  {
    title: 'Note to Buyer',
    type: 'SELLER_CREDIT_NOTE',
    status: 'ORDER_VERIFIED',
    isDisabled: 'true',
    acc_status: 'ORDER_VERIFIED',
  },
];

const EPR_DOC = [
  {
    type: 'TRUCK_BACK_PHOTO',
  },
  {
    type: 'TRUCK_FRONT_PHOTO',
  },
  {
    type: 'EMPTY_WEIGHTBRIDGE',
  },
  {
    type: 'FULL_WEIGHBRIDGE',
  },
  {
    type: 'SELLER_INVOICE',
  },
  {
    type: 'SALES_INVOICE',
  },
  {
    type: 'SALES_EWAY_BILL',
  },
];

const BUYER_DEBITNOTE_REASON_LIST = [
  { id: 'PENDING', name: 'Pending', value: 'PENDING' },
  { id: 'NO', name: 'No, Debit Note', value: 'NO' },
  { id: 'YES', name: 'Yes', value: 'YES' },
];

const SELLER_LIST = [
  { id: 'GST', name: 'GST Amount', value: 'GST Amount' },
  { id: 'MATERIAL', name: 'Next Order Amount', value: 'Next Order Amount' },
];

const CASH_CHALLAN_DURATION = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
];

const PAYMENT_MODES_LIST = [
  { id: 'CHEQUE', name: 'Cheque', value: 'CHEQUE' },
  { id: 'CASH', name: 'Cash', value: 'CASH' },
  { id: 'BANK', name: 'Bank', value: 'BANK' },
  { id: 'DEBIT_NOTE_ADJUSTMENT', name: 'Debit Note Adjustment ', value: 'DEBIT_NOTE_ADJUSTMENT' },
];

const PAYMENT_AGG_TYPE_LIST = [
  { id: 'BILL', name: 'Material Amount', value: 'BILL' },
  { id: 'GST', name: 'Gst Amount', value: 'GST' },
];

const PAYMENT_TRANS_TYPE_LIST = [
  { id: 'BILL', name: 'Transporter Amount', value: 'BILL' },
  { id: 'GST', name: 'Gst Amount', value: 'GST' },
];

const DOC_PREFIX = { AGGR: 'AGGR', RCYCL: 'RCYL', TRANS: 'TRANS', ACCNT: 'ACCNT' };

const PAYMENT_STATUS = [
  { id: 'Processing', name: 'Processing', value: 'Processing' },
  { id: 'Cleared', name: 'Cleared', value: 'Cleared' },
];

const AGREEMENT_TYPE = [
  { id: 'ANNUAL', name: 'Annual', value: 'Annual' },
  { id: 'HALFYEARLY', name: 'Half-Year', value: 'Half-Year' },
  { id: 'QUARTERLY', name: 'Quarterly', value: 'Quarterly' },
  { id: 'ONE-TIME', name: 'One-Time', value: 'One-Time' },
];

const ADMIN_VERIFICATION_DOCS = [
  {
    title: 'Empty Weighbridge Bill',
    type: 'EMPTY_WEIGHTBRIDGE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
    prefix: DOC_PREFIX.AGGR,
    image: 'emptyWeighbridge',
  },
  {
    title: 'Full Weighbridge Bill',
    type: 'FULL_WEIGHBRIDGE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: 'true',
    prefix: DOC_PREFIX.AGGR,
    image: 'fullWeighbridge',
  },
  {
    title: 'Seller Invoice',
    type: 'SELLER_INVOICE',
    status: 'TRUCK_ASSIGNED',
    isDisabled: DOC_PREFIX.AGGR,
    image: 'aggregatorInvoice',
  },
  {
    title: 'LR Copy',
    type: 'LORRY_RECEIPT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: DOC_PREFIX.TRANS,
    image: 'lorryReceipt',
  },
  {
    title: 'Transporter Invoice',
    type: 'TRANSPORTER_INVOICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: DOC_PREFIX.TRANS,
    image: 'transporterInvoice',
  },
  {
    title: 'Proof of Payment',
    type: 'PROOF_OF_PAYMENT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: DOC_PREFIX.TRANS,
    image: 'paymentProof',
  },
  {
    title: 'Truck Front Photo',
    type: 'TRUCK_FRONT_PHOTO',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    image: 'truckFrontPhoto',
  },
  {
    title: 'Truck Back Photo',
    type: 'TRUCK_BACK_PHOTO',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: DOC_PREFIX.TRANS,
    image: 'truckBackPhoto',
  },
];

const TRANSPORTER_PAYMENT_INVOICE = [
  {
    title: 'Transporter Invoice',
    type: 'TRANSPORTER_INVOICE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: DOC_PREFIX.TRANS,
  },
  {
    title: 'LR Copy',
    type: 'LORRY_RECEIPT',
    status: 'MATERIAL_LOADED',
    // isDisabled: 'true',
    acc_status: DOC_PREFIX.TRANS,
  },
  {
    title: 'Proof of Payment',
    type: 'PROOF_OF_PAYMENT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: DOC_PREFIX.TRANS,
  },
];

const ALL_DOCS = {
  AGGREGATOR_DOCUMENTS,
  TRANSPORTER_DOCUMENTS,
  RECYCLER_DOCUMENTS,
  ACCOUNTS_DOCUMENTS,
  ADMIN_VERIFICATION_DOCS,
};
const PO_DOCS = [
  {
    title: 'Recycler Payment Advice',
    type: 'RECYCLER_PAYMENT_ADVICE',
  },
  {
    title: 'Paper PO Document',
    type: 'PO_REF_NUMBER',
  },
];
const ALL_PO_DOCS = {
  PO_DOCS,
};

const LISTS_STATUS = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
};

const KAM_LISTS_STATUS = {
  DRAFT: {
    id: 'DRAFT',
    name: 'Draft',
    colors: '#F1F1F1',
    textColor: '#707070',
  },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    name: 'Under Review',
    colors: '#f3f5ff',
    textColor: '#3044ff',
  },
  APPROVED: {
    id: 'APPROVED',
    name: 'Approved',
    colors: '#e2f5f0',
    textColor: '#00c77e',
  },
  ON_HOLD: {
    id: 'ON_HOLD',
    name: 'On Hold',
    colors: '#fff7ee',
    textColor: '#e8b34a',
  },
  MATCH_FOUND: {
    id: 'MATCH_FOUND',
    name: 'Buyer Found',
    colors: '#fff7f0',
    textColor: '#9e7540',
  },
  SOLD: {
    id: 'SOLD',
    name: 'Sold',
    colors: '#f2eaff',
    textColor: '#00b8a9',
  },
  RESOLD: {
    id: 'RESOLD',
    name: 'Resold',
    colors: '#f2eaff',
    textColor: '#00b8a9',
  },
  EXPIRED: {
    id: 'EXPIRED',
    name: 'Expired',
    colors: '#fff7ee',
    textColor: '#e8b34a',
  },
  REJECTED: {
    id: 'REJECTED',
    name: 'Rejected',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  RETURN: {
    id: 'RETURN',
    name: 'Return',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  RESELL: {
    id: 'RESELL',
    name: 'Resell',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    name: 'In Progress',
    colors: '#fff4df',
    textColor: '#e8b34a',
  },
  CLOSED: {
    id: 'CLOSED',
    name: 'Closed',
    colors: '#f2eaff',
    textColor: '#00b8a9',
  },
};

const SALESORDER_STATUS = {
  IN_REVIEW: {
    id: 'IN_REVIEW',
    name: 'In Review',
    colors: '#FFFAEB',
    textColor: '#B54708',
    borderColor: '#FEDF89',
  },
  APPROVED: {
    id: 'APPROVED',
    name: 'Approved',
    colors: '#EFF8FF',
    textColor: '#175CD3',
    borderColor: '#B2DDFF',
  },
  FULFILLED: {
    id: 'FULFILLED',
    name: 'Fulfilled',
    colors: '#ECFDF3',
    textColor: '#067647',
    borderColor: '#ABEFC6',
  },
  REJECTED: {
    id: 'REJECTED',
    name: 'Rejected',
    colors: '#FEF3F2',
    textColor: '#B42318',
    borderColor: '#FECDCA',
  },
  CLOSED: {
    id: 'CLOSED',
    name: 'Closed',
    colors: '#FEF3F2',
    textColor: '#B42318',
    borderColor: '#FECDCA',
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    name: 'In Progress',
    colors: '#FDF2FA',
    borderColor: '#FCCEEE',
    textColor: '#C11574',
  },
};

const QUOTES_STATUS = {
  IN_REVIEW: {
    id: 'IN_REVIEW',
    name: 'In Review',
    colors: '#f6f0ff',
    textColor: '#6200ee',
  },
  APPROVED: {
    id: 'APPROVED',
    name: 'APPROVED',
    colors: '#e2f5f0',
    textColor: '#66ce8d',
  },
  WAITING_FOR_BUYER_APPROVAL: {
    id: 'WAITING_FOR_BUYER_APPROVAL',
    name: 'Waiting_For_Buyer_Approval',
    colors: '#ddeafe',
    textColor: '#7ca4e3',
  },
  REJECTED: {
    id: 'REJECTED',
    name: 'Rejected',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
};

const GST_FILING_STATUS = {
  NO_DELAYS: {
    id: 'NO_DELAYS',
    name: 'No Delays',
    colors: '#e2f5f0',
    textColor: '#66ce8d',
  },
  ONE_DELAY: {
    id: 'ONE_DELAY',
    name: '1 Delay',
    colors: '#e2f5f0',
    textColor: '#66ce8d',
  },
  TWO_DELAYS: {
    id: 'TWO_DELAYS',
    name: '2 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  THREE_DELAYS: {
    id: 'THREE_DELAYS',
    name: '3 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  FOUR_DELAYS: {
    id: 'FOUR_DELAYS',
    name: '4 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  FIVE_DELAYS: {
    id: 'FIVE_DELAYS',
    name: '5 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  SIX_DELAYS: {
    id: 'SIX_DELAYS',
    name: '6 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  SEVEN_DELAYS: {
    id: 'SEVEN_DELAY',
    name: '7 Delays',
    colors: '#fff2f2',
    textColor: '#66ce8d',
  },
  EIGHT_DELAYS: {
    id: 'EIGHT_DELAYS',
    name: '8 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  NINE_DELAYS: {
    id: 'NINE_DELAYS',
    name: '9 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  TEN_DELAYS: {
    id: 'TEN_DELAYS',
    name: '10 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  ELEVEN_DELAYS: {
    id: 'ELEVEN_DELAYS',
    name: '11 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
  TWELVE_DELAYS: {
    id: 'TWELVE_DELAYS',
    name: '12 Delays',
    colors: '#fff2f2',
    textColor: '#e94340',
  },
};

const QCParameters = {
  RAFIA: {
    id: 'RAFIA',
    name: 'Rafia',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/rafia.png',
    value: 0,
  },
  WIRE_RUSSY: {
    id: 'WIRE_RUSSY',
    name: 'Wire/Russy',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/wire_russy.png',
    value: 0,
  },
  PVC_PLATE: {
    id: 'PVC_PLATE',
    name: 'PVC plate',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/pvc_plate.png',
    value: 0,
  },
  PP_BOTTLE: {
    id: 'PP_BOTTLE',
    name: 'PP bottle',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/pp_bottle.png',
    value: 0,
  },
  COLOR_BOTTLE: {
    id: 'COLOR_BOTTLE',
    name: 'Color Bottle',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/colour_bottle.png',
    value: 0,
  },
  RING_BOTTLE: {
    id: 'RING_BOTTLE',
    name: 'Ring Bottle',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/ring_bottle.png',
    value: 0,
  },
  DIRTY_BOTTLE: {
    id: 'DIRTY_BOTTLE',
    name: 'Dirty Bottle',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/dirty_bottle.png',
    value: 0,
  },
  MOISTURE: {
    id: 'MOISTURE',
    name: 'Moisture',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/moisture.png',
    value: 0,
  },
  DUST: {
    id: 'DUST',
    name: 'Dust',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/dust.png',
    value: 0,
  },
  OILY: {
    id: 'OILY',
    name: 'Oily',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/oily.png',
    value: 0,
  },
  NON_PET: {
    id: 'NON_PET',
    name: 'Non PET',
    icon: 'https://uzed.s3.ap-south-1.amazonaws.com/recykal/icons/quality_params/non_pet.png',
    value: 0,
  },
};

const ROLE_PRIORITY = [
  {
    Role: 'ROLE_MARKETPLACE_BUSINESS',
    Priority: 1,
    title: 'BUSINESS_HEAD',
  },
  {
    Role: 'ROLE_ADMIN',
    Priority: 2,
    title: 'ADMIN',
  },
  {
    Role: 'ROLE_MARKETPLACE_ACCOUNTS',
    Priority: 3,
    title: 'ACCOUNTS',
  },
  {
    Role: 'ROLE_KEY_ACCOUNT_MANAGER',
    Priority: 4,
    title: 'KEY_ACCOUNT_MANAGER',
  },
  {
    Role: 'ROLE_USER',
    Priority: 5,
    title: 'USER',
  },
  {
    Role: 'ROLE_OPERATIONS',
    Priority: 6,
    title: 'OPERATIONS',
  },
  {
    Role: 'ROLE_UNIVERSAL_USER',
    Priority: 7,
    title: 'UNIVERSAL_USER',
  },
];

const MAX_CHARACTERS_LENGTH = 15;

const RECYCLER_SUPER_ROLE = 'ROLE_RECYCLER_SUPER_ADMIN';

const PAYMENT_TERM_EVENT = [
  { id: 1, event: 'APPROVED', name: 'Shipment Approved' },
  { id: 2, event: 'TRUCK_ASSIGNED', name: 'Truck Assigned' },
  { id: 3, event: 'MATERIAL_LOADED', name: 'Material Loaded' },
  { id: 4, event: 'DISPATCHED', name: 'Dispatched' },
  { id: 5, event: 'RECEIVED_BY_RECYCLER', name: 'Delivered' },
  { id: 6, event: 'QC_DONE', name: 'QC Done' },
];

const SALE_ORDER_DOCUMENT_STATUS = {
  PENDING: {
    id: 'PENDING',
    name: 'Pending',
    statusText: null,
    role: null,
    textColor: '#D99148',
  },
  UPLOADED: {
    id: 'UPLOADED',
    name: 'Uploaded',
    statusText: null,
    role: null,
    textColor: '#2A91C7',
  },
  VALID_ADMIN: {
    id: 'VALID_ADMIN',
    name: 'Valid by Admin',
    statusText: 'Valid',
    role: 'Admin',
    textColor: '#4D8C6C',
  },
  VALID_ACCOUNTS: {
    id: 'VALID_ACCOUNTS',
    name: 'Valid by Accounts',
    statusText: 'Valid',
    role: 'Accounts',
    textColor: '#4D8C6C',
  },
  INVALID_ADMIN: {
    id: 'INVALID_ADMIN',
    name: 'In-Valid by Admin',
    statusText: 'Invalid',
    role: 'Admin',
    textColor: '#EA6461',
  },
  INVALID_ACCOUNTS: {
    id: 'INVALID_ACCOUNTS',
    name: 'In-Valid by Accounts',
    statusText: 'Invalid',
    role: 'Accounts',
    textColor: '#EA6461',
  },
  REUPLOADED: {
    id: 'REUPLOADED',
    name: 'Reuploaded',
    statusText: null,
    role: null,
    textColor: '#C7972A',
  },
  DATA_UPDATED: {
    id: 'DATA_UPDATED',
    name: 'Data Updated',
    statusText: null,
    role: null,
    textColor: '#C7972A',
  },
};

const INCORRECT_DOCUMENT_TYPES = {
  SELLER_INVOICE: {
    id: 'SELLER_INVOICE',
    name: 'Aggregator Invoice',
    reasons: [
      'aggregatorInvoiceSignStamp',
      'aggregatorInvoiceBillDate',
      'aggregatorInvoicedescriptionOfGoods',
      'aggregatorInvoiceQtyAndRate',
      'aggregatorInvoiceBillNumber',
      'rapidueGstinAndName',
      'aggregatorInvoiceAmountInWords',
      'aggregatorInvoiceDispatchedAddress',
    ],
  },
  SELLER_EWAY_BILL: {
    id: 'SELLER_EWAY_BILL',
    name: 'Aggregator E-way Bill',
    reasons: [],
  },
  TRUCK_FRONT_PHOTO: {
    id: 'TRUCK_FRONT_PHOTO',
    name: 'Truck Front Photo',
    reasons: ['truckFrontVehicleNumberVisible'],
  },
  TRUCK_BACK_PHOTO: {
    id: 'TRUCK_BACK_PHOTO',
    name: 'Truck Back Photo',
    reasons: ['truckBackVehicleNumberVisible'],
  },
  EMPTY_WEIGHTBRIDGE: {
    id: 'EMPTY_WEIGHTBRIDGE',
    name: 'Empty Weigh Bridge',
    reasons: [
      'emptyWeightbridgeTareWeightAvailable',
      'emptyWeightbridgeVehicleNumberVisible',
      'emptyWeightbridgeDateAvailable',
    ],
  },
  FULL_WEIGHBRIDGE: {
    id: 'FULL_WEIGHBRIDGE',
    name: 'Full Weigh Bridge',
    reasons: [
      'fullWeightBridgeTareWeightAvailable',
      'fullWeightBridgeGrossWeightAvailable',
      'fullWeightBridgeNetWeightAvailable',
      'fullWeightBridgeVehicleNumberVisible',
      'fullWeightBridgeDateAvailable',
    ],
  },
  // Added more docs from valid point
  RECYCLER_WEIGHBRIDGE_BILL: {
    name: 'Buyer Weighbridge',
    id: 'RECYCLER_WEIGHBRIDGE_BILL',
    reasons: [],
  },
  QC_DONE: {
    name: 'Buyer QC Report',
    id: 'QC_DONE',
    reasons: [],
  },
  DEBIT_NOTE: {
    name: 'Debit Note',
    id: 'DEBIT_NOTE',
    reasons: [],
  },
  LR_COPY: {
    name: 'Seller LR Copy',
    id: 'LR_COPY',
    reasons: [],
  },
  GRN_OTHERS: {
    name: 'GRN/Others',
    id: 'GRN',
    reasons: [],
  },
  LORRY_RECEIPT: {
    name: 'Transporter LR Copy',
    id: 'LORRY_RECEIPT',
    reasons: [],
  },
};

const CAMPAIGN_STATUS = {
  DRAFT: {
    id: 'DRAFT',
    name: 'Draft',
    textColor: 'brown',
  },
  ACTIVE: {
    id: 'ACTIVE',
    name: 'Active',
    textColor: '#3AA12B',
  },
  INACTIVE: {
    id: 'INACTIVE',
    name: 'Inactive',
    textColor: '#FF5722',
  },
};

const CAMPAIGN_SELLER_STATUS = {
  // VIEW: {
  //   id: 'VIEW',
  //   name: 'View',
  //   colors: '#f6f0ff',
  //   textColor: '#6200ee',
  // },
  USED: {
    id: 'USED',
    name: 'Used',
    colors: '#e2f5f0',
    textColor: '#00c77e',
  },
  UNUSED: {
    id: 'UNUSED',
    name: 'Unused',
    colors: '#fff7ee',
    textColor: '#9e7540',
  },
};

const CAMPAIGN_SELLER_TYPE = [
  { id: 'BALER,TRADER,AGGREGATOR,RECYCLER', name: 'All', value: 'All' },
  { id: 'BALER', name: 'Baler', value: 'Baler' },
  { id: 'TRADER', name: 'Trader', value: 'Trader' },
  { id: 'AGGREGATOR', name: 'Aggregator', value: 'Aggregator' },
  { id: 'RECYCLER', name: 'Recycler', value: 'Recycler' },
];

const CAMPAIGN_AGGREGATOR_STATUS = [
  { id: 'NEW', name: 'New', value: 'New' },
  { id: 'ACTIVE', name: 'Active', value: 'Active' },
  { id: 'ONBOARDED', name: 'Onboarded', value: 'Onboarded' },
  { id: 'ACQUIRED', name: 'Acquired', value: 'Acquired' },
  { id: 'POTENTIAL_CHURN', name: 'Potential Churned', value: 'Potential Churned' },
  { id: 'CHURNED', name: 'Churned', value: 'Churned' },
  { id: 'REACTIVATED', name: 'Reactivated', value: 'Reactivated' },
  {
    id: 'ACQUIRED_BUT_POTENTIAL_CHURN',
    name: 'Acquired but Potential Churned',
    value: 'Acquired but Potential Churned',
  },
  { id: 'DEACTIVATED', name: 'Deactivated', value: 'Deactivated' },
];

const CAMPAIGN_STATUS_EVENTS = [
  { id: 'MATERIAL_LOADED', name: 'Material Loaded', value: 'Material Loaded' },
  { id: 'DISPATCHED', name: 'Dispatched', value: 'Dispatched' },
  { id: 'RECEIVED_BY_RECYCLER', name: 'Delivered', value: 'Delivered' },
  { id: 'QC_DONE', name: 'QC Done', value: 'Qc Done' },
];

const CAMPAIGN_CONDITIONS = [
  { id: 'is', name: 'is', value: 'is' },
  { id: 'is greater than or equal to', name: '>=', value: '>=' },
  { id: 'is less than or equal to', name: '<=', value: '<=' },
  { id: 'is greater than', name: '<', value: '<' },
  { id: 'is less than', name: '>', value: '>' },
  { id: 'is equal to', name: '=', value: '=' },
  { id: 'is not equal to', name: '!=', value: '!=' },
  { id: 'is not', name: 'is not', value: 'is not' },
];

const CAMPAIGN_BONUS_DROPDOWN = [
  { id: 'BONUS', name: 'Bonus', value: 'Bonus' },
  { id: 'DISCOUNT', name: 'Discount', value: 'Discount' },
];

const CAMPAIGN_PAYMENT_DROPDOWN = [{ id: 'PAYMENT', name: 'Payment', value: 'Payment' }];

const CAMPAIGN_PAYMENT_WHEN_OPTIONS = [{ id: 'after', name: 'after', value: 'after' }];

const CAMPAIGN_BONUS_INDEX_DROPDOWN = [
  { id: 'AND', name: 'And', value: 'And' },
  // { id: 'OR', name: 'Or', value: 'Or' },
];

const CAMPAIGN_WORKFLOW_STATUS = [
  { id: 'ORDER_STATUS', name: 'Order Status', value: 'Order Status' },
  // { id: 'LISTING_STATUS', name: 'Listing Status', value: 'Listing Status' },
  // { id: 'DISPATCHED_DATE', name: 'Dispatched Date', value: 'Dispatched Date' },
  // { id: 'MATERIAL_LOADED_DATE', name: 'Material Loaded Date', value: 'Material Loaded Date' },
  // { id: 'QC_DONE_DATE', name: 'Qc Done', value: 'Qc Done' },
];

const CAMPAIGN_OFFERPRICE_LIST = [
  { id: 'Recykal Offer Price', name: 'Recykal Offer Price', value: 'Recykal Offer Price' },
  // { id: 'Seller Own Price', name: 'Seller Own Price', value: 'Seller Own Price' },
];

const NOTE_BUYER_DIFF_NOTE_SELLER = {
  Plastic: [
    { minThreshold: -Infinity, maxThreshold: 2999, authority: AUTHORITY.ADMIN },
    { minThreshold: 3000, maxThreshold: 25000, authority: AUTHORITY.FULFILMENT_LEAD },
    { minThreshold: 25001, maxThreshold: Infinity, authority: AUTHORITY.COO },
  ],
  Paper: [
    { minThreshold: -Infinity, maxThreshold: 2499, authority: AUTHORITY.ADMIN },
    { minThreshold: 2500, maxThreshold: 25000, authority: AUTHORITY.FULFILMENT_LEAD },
    { minThreshold: 25001, maxThreshold: Infinity, authority: AUTHORITY.COO },
  ],
  Metal: [
    { minThreshold: -Infinity, maxThreshold: 999, authority: AUTHORITY.ADMIN },
    { minThreshold: 1000, maxThreshold: 5000, authority: AUTHORITY.FULFILMENT_LEAD },
    { minThreshold: 5001, maxThreshold: Infinity, authority: AUTHORITY.COO },
  ],
};

const KYC_STATUS = {
  SELLER_AGREEMENT: { type: 'SELLER_AGREEMENT', name: 'Agreement' },
};

const ACCOUNTS_DOCUMENTS_SELLER = [
  {
    title: 'QC Report for Seller',
    type: 'SELLER_QC_REPORT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  {
    title: 'WeighBridge for Seller',
    type: 'SELLER_WEIGHTBRIDGE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  {
    title: 'Material photos for Seller',
    type: 'SELLER_MATERIAL_PHOTOS',
    status: 'MATERIAL_LOADED',
  },
  {
    title: 'Debit Note for Seller',
    type: 'SELLER_DEBIT_NOTE',
    status: 'MATERIAL_LOADED',
  },
];

const PAYMENT_MATERIAL_STATUS = [
  { id: 'PAID', label: 'Paid', accessor: 'AMOUNT', textColor: '#27B711' },
  { id: 'PARTIALLY_PAID', label: 'Partially Paid', accessor: 'AMOUNT', textColor: '#E79A00' },
  { id: 'DUE', label: 'Due', accessor: 'DATE', textColor: '#707070' },
  { id: 'NOT_DUE', label: 'Not Due', accessor: 'DATE', textColor: '#FF7564' },
  { id: 'OVER_DUE', label: 'Over Due', accessor: 'DATE', textColor: '#F56E6E' },
  { id: 'PROCESSING', label: 'Processing', accessor: 'BILL' },
  { id: 'Processing', label: 'Processing', accessor: 'BILL', textColor: '#1A55B3' },
];

const PAYMENT_GST_STATUS = [
  { id: 'PAID', label: 'Paid', accessor: 'AMOUNT', textColor: '#27B711' },
  { id: 'PARTIALLY_PAID', label: 'Partially Paid', accessor: 'AMOUNT', textColor: '#E79A00' },
  { id: 'NOT_PAID', label: 'Not Paid', accessor: 'AMOUNT', textColor: '#FF7564' },
  { id: 'PROCESSING', label: 'Processing', accessor: 'BILL' },
];

const allState = { name: 'All States', id: 'All', type: 'STATE' };
const allCity = { name: 'All Cities', id: 'All', type: 'CITY' };
const allItems = { name: 'All Items', id: 'All' };

const TRACKING_STATUS = {
  PENDING: {
    id: 'PENDING',
    name: 'Pending',
    textColor: '#FF7564',
  },
  ACCEPTED: {
    id: 'ACCEPTED',
    name: 'Accepted',
    textColor: '#FF7564',
  },
  INITIATED: {
    id: 'INITIATED',
    name: 'Initiated',
    textColor: '#FF7564',
  },
  ACTIVE: {
    id: 'ACTIVE',
    name: 'Active',
    textColor: '#58BC7B',
  },
  COMPLETED: {
    id: 'COMPLETED',
    name: 'Completed',
    textColor: '#939393',
  },
  // Currently we havent implemented manual option.
  MANUAL: {
    id: 'MANUAL',
    name: 'Manual',
    textColor: '#FFA52B',
  },
};

const TRIP_STATUS = {
  TO_BE_DISPATCHED: {
    id: 'TO_BE_DISPATCHED',
    name: 'To be Dispatched',
    textColor: '#D8A45E',
  },
  DISPATCHED: {
    id: 'DISPATCHED',
    name: 'Dispatched',
    textColor: '#FFA52B',
  },
  ABOUT_TO_REACH: {
    id: 'ABOUT_TO_REACH',
    name: 'About to Reach',
    textColor: '#58BC7B',
  },
  REACHED: {
    id: 'REACHED',
    name: 'Reached',
    textColor: '#939393',
  },
};

const ROLE_MATERIAL = {
  Plastic: [AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE_PLASTIC, AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE],
  Paper: [AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE, AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE_PAPER],
  Metal: [AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE_METAL, AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE],
  'E-Waste': [AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE_E_WASTE, AUTHORITY.ROLE_ACCOUNTS_RECEIVABLE],
};

const APPLEAD_DOCUMENT_TYPE = [
  { id: 'RECYCLER', name: 'Recycler', value: 'RECYCLER' },
  { id: 'REFURBISHER', name: 'Refurbisher', value: 'REFURBISHER' },
];

const TRACK_NOW_STATUS = [
  ORDER_STATUS.TRUCK_ASSIGNED,
  ORDER_STATUS.MATERIAL_LOADED,
  ORDER_STATUS.ORDER_VERIFIED,
  ORDER_STATUS.DISPATCHED,
  ORDER_STATUS.RECEIVED_BY_RECYCLER,
  ORDER_STATUS.QC_DONE,
  ORDER_STATUS.RESELL,
  ORDER_STATUS.RETURN,
];

const TRACK_MANUAL_STATUS = [
  ORDER_STATUS.TRUCK_ASSIGNED,
  ORDER_STATUS.MATERIAL_LOADED,
  ORDER_STATUS.ORDER_VERIFIED,
  ORDER_STATUS.DISPATCHED,
];

const TRACKING_VIEW = {
  TO_BE_DISPATCHED: {
    id: 'tracking_to_be_dispatch',
  },
  DISPATCHED: {
    id: 'tracking_dispatch',
  },
  REACHED: { id: 'tracking_reached' },
};

const BankStatus = {
  PENDING: {
    id: 'PENDING',
    name: 'Pending',
  },
  E_VERIFIED: {
    id: 'E_VERIFIED',
    name: 'e-verified',
  },
  VERIFICATION_INITIALIZED: {
    id: 'VERIFICATION_INITIALIZED',
    name: 'Verification Pending',
  },
  APPROVED: {
    id: 'APPROVED',
    name: 'Approved',
  },
  REJECTED: {
    id: 'REJECTED',
    name: 'Rejected',
  },
};

const BANK_DOCUMENT_REJECTION_REASON = [
  {
    id: 1,
    name: 'Invalid Document',
  },
  { id: 2, name: 'The name on GST and Document did not match' },
];

const AGGREGATOR_RATING_STATUS = {
  PENDING: {
    id: 'PENDING',
    name: 'Pending',
    colors: '#FFE3C9',
    textColor: '#98716C',
  },
  CAPTURED: {
    id: 'CAPTURED',
    name: 'Captured',
    colors: '#E7FFC9',
    textColor: '#328E4C',
  },
};
const LIABILITY_OPTIONS = [
  { id: 0, Option: '100%' },
  { id: 1, Option: '95-100%' },
  { id: 2, Option: '<95%' },
];

const HSN_OPTIONS = [
  { id: 0, Option: 'Related' },
  { id: 1, Option: 'Unrelated' },
];

const AADHAR_STATUS = [
  { id: 0, Option: 'Yes' },
  { id: 1, Option: 'No' },
];

const COMPLIANCE_STATUS = {
  NOT_INITIATED: {
    id: 'NOT_INITIATED',
    name: 'Not Initiated',
    colors: '#D9D9D9',
    textColor: '#707070',
  },
  UNDER_REVIEW: {
    id: 'UNDER_REVIEW',
    name: 'Under Review',
    colors: '#FFF1E4',
    textColor: '#DBA26E',
  },
  VERIFIED: {
    id: 'VERIFIED',
    name: 'Verified',
    colors: '#EDFFE0',
    textColor: '#73B343',
  },
  FAILED: {
    id: 'FAILED',
    name: 'Failed',
    colors: '#FFF5F5',
    textColor: '#FF6969',
  },
};

const OSV_STATUS = {
  NOT_INITIATED: {
    id: 'NOT_INITIATED',
    name: 'Not Initiated',
    colors: '#D9D9D9',
    textColor: '#707070',
  },
  UNDER_REVIEW: {
    id: 'UNDER_REVIEW',
    name: 'Under Review',
    colors: '#FFF1E4',
    textColor: '#DBA26E',
  },
  VERIFIED: {
    id: 'VERIFIED',
    name: 'Verified',
    colors: '#EDFFE0',
    textColor: '#73B343',
  },
  FAILED: {
    id: 'FAILED',
    name: 'Failed',
    colors: '#FFF5F5',
    textColor: '#FF6969',
  },
};

const LEAD_STATUS = {
  NOT_INITIATED: {
    id: 'NOT_INITIATED',
    name: 'Not Initiated',
    colors: '#D9D9D9',
    textColor: '#707070',
  },
  UNDER_REVIEW: {
    id: 'UNDER_REVIEW',
    name: 'Under Review',
    colors: '#FFF1E4',
    textColor: '#DBA26E',
  },
  VERIFIED: {
    id: 'VERIFIED',
    name: 'Verified',
    colors: '#EDFFE0',
    textColor: '#73B343',
  },
  REJECTED: {
    id: 'REJECTED',
    name: 'Rejected',
    colors: '#FFF5F5',
    textColor: '#FF6969',
  },
};

const DEBIT_NOTE_REASONS = [
  { id: 'Quantity Shortfall', value: 'Quantity Shortfall', label: 'Quantity Shortfall' },
  { id: 'Quality Rejected', value: 'Quality Rejected', label: 'Quality Rejected' },
  { id: 'Rate Variance', value: 'Rate Variance', label: 'Rate Variance' },
  { id: 'Quality Issue', value: 'Quality Issue', label: 'Quality Issue' },
  { id: 'Completely Rejected', value: 'Completely Rejected', label: 'Completely Rejected' },
  { id: 'Partially Rejected', value: 'Partially Rejected', label: 'Partially Rejected' },
  { id: 'Excess Quantity', value: 'Excess Quantity', label: 'Excess Quantity' },
  { id: 'Better Quality', value: 'Better Quality', label: 'Better Quality' },
  { id: 'Reversal by Customer', value: 'Reversal by Customer', label: 'Reversal by Customer' },
  { id: 'Internal reversal', value: 'Internal reversal', label: 'Internal reversal' },
];

const TOAST_TYPES = {
  SUCCESS: 'success',
  WARN: 'warn',
  ERROR: 'error',
  INFO: 'info',
};

const AGGREGATOR_CREATING_EWAY_BILL = [
  { id: 'Yes', value: 'true', label: 'Yes' },
  { id: 'No', value: 'false', label: 'No' },
];

const ListingDayStatus = [
  { id: 'TODAY', name: 'Today' },
  { id: 'TOMORROW', name: 'Tomorrow' },
  { id: 'IN_NEXT_3_DAYS', name: 'In Next 3 Days' },
  { id: 'IN_NEXT_7_DAYS', name: 'In Next 7 Days' },
];

const PURCHASE_ORDER_URL = (ID) => `/orders/${ID}/details-po`;
const SHIPMENTS_URL = '/orders/shipment/';
const LISTING_ORDER_URL = '/lists/listing-details/';
const SALE_ORDERS_URL = '/saleorder-details/';
const AGGREGATOR_DETAILS_URL = '/aggregatorDetails';

const ZOHO_STATUS = {
  VOID_STATUS: 'void',
  OPEN_STATUS: 'open',
};

const CATEGORY_HEAD = [
  {
    email: 'manish.gupta@recykal.com',
    type: 'Plastic',
  },
  {
    email: 'pramod.sahoo@recykal.com',
    type: 'Paper',
  },
  {
    email: 'rohit.singh@recykal.com',
    type: 'Metal',
  },
];

const CATEGORY_HEAD_STAGE = [
  {
    email: 'gagantest@recykal.com',
    type: 'Plastic',
  },
  {
    email: 'gagantest@recykal.com',
    type: 'Paper',
  },
  {
    email: 'gagantest@recykal.com',
    type: 'Metal',
  },
];

export const ROLES_EDIT_CLONE = {
  CLONE: 'clone',
  EDIT: 'edit',
};

export const PARENT_NAV_BAR = [
  'items',
  'orders',
  'logistics',
  'customers',
  'payments',
  'gst',
  'system_config',
];

export const ROLES_PERMISSIONS = {
  FULL_ACCESS: 'Full Access',
  VIEW: 'View',
  CREATE: 'Create',
  EDIT: 'Edit',
};

const LISTING_STATUS = [
  { id: 0, label: 'Draft', name: 'DRAFT' },
  { id: 1, label: 'Under Review', name: 'IN_REVIEW' },
  { id: 2, label: 'Expired', name: 'EXPIRED' },
  { id: 3, label: 'Rejected', name: 'REJECTED' },
  { id: 4, label: 'Closed', name: 'CLOSED' },
];

const TOLERANCE_OPTIONS = [
  { id: 'PERCENTAGE', name: 'Percentage' },
  { id: 'NUMBER', name: 'Number' },
  { id: 'TEXT', name: 'Text' },
];

const TOLERANCE_OPTIONS_VIEW = {
  PERCENTAGE: 'Percentage',
  NUMBER: 'Number',
  TEXT: 'Text',
  '%': '%',
};
const ACTIVITY_EVENTS = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

const RECYCLER_REASONS_FOR_UNAVAILABLE_PRICE = [
  { id: 0, label: 'Recycler Not responding', name: 'RECYCLER_NOT_RESPONDING' },
  { id: 1, label: 'Didnot Call', name: 'DIDNOT_CALL' },
];

const AGGREGATOR_REASONS_FOR_UNAVAILABLE_PRICE = [
  { id: 0, label: 'Aggregator Not responding', name: 'AGGREGATOR_NOT_RESPONDING' },
  { id: 1, label: 'Didnot Call', name: 'DIDNOT_CALL' },
];

const PURCHASE_ORDER_STATUSES = [
  { id: 1, label: 'Approved', name: 'APPROVED' },
  { id: 2, label: 'Fulfilled', name: 'FULFILLED' },
  { id: 4, label: 'Closed', name: 'CLOSED' },
  { id: 5, label: 'In Progress', name: 'IN_PROGRESS' },
];

export const VEHICLE_IMAGES = ['TRUCK_FRONT_PHOTO', 'TRUCK_BACK_PHOTO'];
export const WEIGHBRIDGE_IMAGES = ['EMPTY_WEIGHTBRIDGE', 'FULL_WEIGHBRIDGE'];
export const SALES_IMAGES = ['SELLER_INVOICE', 'SELLER_EWAY_BILL'];

const AGGREGATOR_DEPARTMENTS = [
  { value: 'ROLE_AGGREGATOR_ADMIN', label: 'Admin', id: 'ROLE_AGGREGATOR_ADMIN' },
  { value: 'ROLE_AGGREGATOR_ACCOUNTS', label: 'Accounts', id: 'ROLE_AGGREGATOR_ACCOUNTS' },
  { value: 'ROLE_AGGREGATOR_OPERATIONS', label: 'Operations', id: 'ROLE_AGGREGATOR_OPERATIONS' },
];

export const AGGREGATOR_APP_LEADS_STEPPER = [
  { id: 1, label: 'Basic Information' },
  { id: 2, label: 'KYC Details' },
  { id: 3, label: 'Documents & Agreement' },
];

export const RECYCLER_APP_LEADS_STEPPER = [
  { id: 1, label: 'Basic Information' },
  { id: 2, label: 'Documents Upload & KYC Verification' },
  { id: 3, label: 'KYC Details' },
  { id: 4, label: 'Documents & Agreement' },
];

const ALL_VALID_DOC_TYPES = [
  'CONSENT_TO_OPERATE',
  'CONSENT_TO_ESTABLISHMENT',
  'CFO',
  'PCB_AUTHORISATION',
  'TSDF_AGREEMENT',
  'DOWNSTREAM_AGREEMENT',
  'PARTNERSHIP_DEED',
  'INCORPORATION_REGISTRATION_CERTIFICATE',
  'AADHAAR_CARD',
  'PLANT_MACHINE_PICS',
];

const VALIDATION_DOCUMENT_MAPPER = [
  {
    id: 'CONSENT_TO_OPERATE',
    title: 'Consent to operate',
    fields: ['capacity', 'expiryDate', 'type'],
    docType: 'CONSENT_TO_OPERATE',
    fieldsMandatory: false,
  },
  {
    id: 'CONSENT_TO_ESTABLISHMENT',
    title: 'Consent to establishment',
    fields: ['capacity', 'expiryDate', 'type'],
    docType: 'CONSENT_TO_ESTABLISHMENT',
    fieldsMandatory: false,
  },
  {
    id: 'CFO',
    title: 'CFO',
    fields: ['capacity', 'expiryDate', 'type'],
    docType: 'CFO',
    fieldsMandatory: false,
  },
  {
    id: 'PCB_AUTHORISATION',
    title: 'PCB Authorisation',
    fields: ['capacity', 'expiryDate', 'autorisingBody', 'type'],
    docType: 'PCB_AUTHORISATION',
    fieldsMandatory: false,
  },
  {
    id: 'TSDF_AGREEMENT',
    title: 'TSDF Agreement/certificate',
    fields: ['capacity', 'expiryDate', 'tsdfName'],
    docType: 'TSDF_AGREEMENT',
    fieldsMandatory: false,
  },
  {
    id: 'DOWNSTREAM_AGREEMENT',
    title: 'Downstream Agreement',
    fields: ['expiryDate', 'downStreamPartner', 'pcbNumber'],
    docType: 'DOWNSTREAM_AGREEMENT',
    fieldsMandatory: false,
  },
  {
    id: 'PASSBOOK',
    title: 'Passbook (Optional)',
    fields: ['capacity', 'expiryDate', 'authorisingState'],
    docType: 'PASSBOOK',
    fieldsMandatory: false,
  },
];

const PLANT_MACHINE_MAPPER = [
  {
    id: 'PLANT_MACHINE_PICS',
    title: '',
    fields: [],
    docType: 'PLANT_MACHINE_PICS',
    fieldsMandatory: false,
  },
];

const KYC_VERIFICATION_MAPPER = [
  {
    id: 'PARTNERSHIP_DEED',
    title: 'Partnership Deed',
    fields: ['listOfPartners', 'contactDetails'],
    docType: 'PARTNERSHIP_DEED',
    fieldsMandatory: false,
  },
  {
    id: 'INCORPORATION_REGISTRATION_CERTIFICATE',
    title: 'Incorporation Certificate',
    fields: ['incorporationDate'],
    docType: 'INCORPORATION_REGISTRATION_CERTIFICATE',
    fieldsMandatory: false,
  },
  {
    id: 'AADHAAR_CARD',
    title: 'Adhaar (Owner)',
    fields: [],
    docType: 'AADHAAR_CARD',
    fieldsMandatory: false,
  },
];

const ADDITIONAL_DOCUMENTS_MAPPER = [
  {
    id: 'EPF_ESIC',
    title: 'EPF/ESIC (Optional)',
    fields: [],
    docType: 'EPF_ESIC',
    fieldsMandatory: false,
  },
  {
    id: 'ISO_CERTIFICATES',
    title: 'ISO Certificates (Optional)',
    fields: [],
    docType: 'ISO_CERTIFICATES',
    fieldsMandatory: false,
  },
  {
    id: 'R2',
    title: 'R2 Certificate (Optional)',
    fields: [],
    docType: 'R2',
    fieldsMandatory: false,
  },
  {
    id: 'OASIS',
    title: 'OASIS Certificate (Optional)',
    fields: [],
    docType: 'OASIS',
    fieldsMandatory: false,
  },
];

const ATTRIBUTE_DATA_TYPES = [
  { id: 'NUMERIC', name: 'Numeric', value: 'NUMERIC' },
  { id: 'STRING', name: 'String', value: 'STRING' },
];

const QC_ATTRIBUTE_DATA_TYPES = [
  { id: 'NUMERIC', name: 'Numeric', value: 'NUMERIC' },
  { id: 'STRING', name: 'String', value: 'STRING' },
  { id: 'SINGLE_CHOICE', name: 'Single-choice', value: 'SINGLE_CHOICE' },
  { id: 'CHECKBOX', name: 'Checkbox', value: 'CHECKBOX' },
];

const LISTING_DOC_LIST = [
  {
    title: 'Material Images',
    type: 'LISTING_DOC',
  },
  {
    title: 'QC Report',
    type: 'M3_QC_REPORT',
  },
];

const VALID_DOCUMENT_TYPES = [
  'CONSENT_TO_OPERATE',
  'CONSENT_TO_ESTABLISHMENT',
  'CFO',
  'PCB_AUTHORISATION',
  'TSDF_AGREEMENT',
  'DOWNSTREAM_AGREEMENT',
  'PASSBOOK',
  'PARTNERSHIP_DEED',
  'INCORPORATION_REGISTRATION_CERTIFICATE',
];

const BANK_VERIFICATION_STATUS_ERROR_CODE = {
  zoop001: 'ZOOP001',
  bank001: 'BANK001',
  bank004: 'BANK004',
  bank003: 'BANK003',
  customer001: 'CUSTOMER001',
  generic001: 'GENRIC001',
};

const ACCOUNTS_DOCUMENTS_SELLER_MANDATORY = [
  {
    title: 'QC Report for Seller',
    type: 'SELLER_QC_REPORT',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  {
    title: 'WeighBridge for Seller',
    type: 'SELLER_WEIGHTBRIDGE',
    status: 'MATERIAL_LOADED',
    isDisabled: 'true',
    acc_status: 'PAYMENT_DONE',
  },
  {
    title: 'Debit Note for Seller',
    type: 'SELLER_DEBIT_NOTE',
    status: 'MATERIAL_LOADED',
  },
];

const STATUS_TRACKNOW = [
  ORDER_STATUS.TRUCK_ASSIGNED,
  ORDER_STATUS.MATERIAL_LOADED,
  ORDER_STATUS.ORDER_VERIFIED,
  ORDER_STATUS.DISPATCHED,
  ORDER_STATUS.RECEIVED_BY_RECYCLER,
  ORDER_STATUS.RETURN,
  ORDER_STATUS.RESELL,
  ORDER_STATUS.QC_DONE,
  ORDER_STATUS.PARTIAL_PAYMENT_RECEIVED,
  ORDER_STATUS.DOCUMENT_PENDING,
  ORDER_STATUS.ORDER_COMPLETED,
];

const STATUS_FASTAG = [
  ORDER_STATUS.DISPATCHED,
  ORDER_STATUS.RECEIVED_BY_RECYCLER,
  ORDER_STATUS.RETURN,
  ORDER_STATUS.RESELL,
  ORDER_STATUS.QC_DONE,
  ORDER_STATUS.PARTIAL_PAYMENT_RECEIVED,
  ORDER_STATUS.DOCUMENT_PENDING,
  ORDER_STATUS.ORDER_COMPLETED,
];

const ADMIN_EDIT_STATUS = [ORDER_STATUS.TRUCK_ASSIGNED, ORDER_STATUS.MATERIAL_LOADED];

const SALE_ORDER_DETAILS_LIST = [
  {
    id: SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.SELLER_ORDER_DETAILS.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.BUYER_ORDER_DETAILS.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.LOGISTICS_DETAILS.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.id,
    title: SALE_ORDER_DETAILS_SECTIONS.TRUCK_TRACKING.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.DISPATCHED_DETAILS.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.ACCOUNTS_DOCUMENTS.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.id,
    title: SALE_ORDER_DETAILS_SECTIONS.TRANSPORTER_DOCUMENT.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.id,
    title: SALE_ORDER_DETAILS_SECTIONS.NOTE_FROM_BUYER.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.SELLER_DOCUMENTS.title,
    isOpen: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.DELIVERY_DETAILS.title,
    isOpen: true,
    isVisible: true,
    isDisableCollapse: false,
  },
  {
    id: SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.id,
    title: SALE_ORDER_DETAILS_SECTIONS.PICKUP_DETAILS.title,
    isOpen: true,
    isDisableCollapse: false,
  },

  {
    id: SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.id,
    title: SALE_ORDER_DETAILS_SECTIONS.PASS_DOCS_TO_SELLER.title,
    isOpen: true,
    isDisableCollapse: false,
  },
];

const COMMENTS_TAB_LIST = [
  { id: 'VEHICLE_DOCUMENTS', title: 'Vehicle Images' },
  { id: 'WEIGHBRIDGE_DOCUMENTS', title: 'Weigh-bridge Details' },
  { id: 'SALES_DOCUMENTS', title: 'Seller Invoice & E-way Bill' },
];

const AGGR_COMMENTS_TEXT = {
  INVOICE_REJECT_COMMENT: 'Invoice number updated',
  VEHICLE_REJECT_COMMENT: 'Vehicle number updated',
  WEIGHBRIDGE_REJECT_COMMENT: 'Weighbridge number updated',
};

const AGGR_PAYMENT_TABS = [
  { id: 0, title: 'All', name: 'ALL' },
  { id: 1, title: 'Level 1 : Payments to be Initiated', name: 'INITIATED' },
  { id: 2, title: 'Level 2 : Payments to be Processed', name: 'IN_PROCESS' },
  { id: 3, title: 'Level 3 : Payments to be Approved', name: 'APPROVED' },
];

export const AGGR_PAYMENT_VERIFICATION_CARDS = [
  { id: 1, title: 'L1 : Payments to be Initiated' },
  { id: 2, title: 'L2 : Payments to be Processed' },
  { id: 3, title: 'L3 : Payments to be Approved' },
];

const ADDITIONAL_DOCS_GST1 = [
  // {
  //   title: 'Cash Challan',
  //   type: 'CASH_CHALLAN',
  //   isDisabled: true,
  // },
  {
    title: 'GSTR 2B',
    type: 'GSTR_2B_DOCUMENT',
    isDisabled: true,
  },
  {
    title: 'GSTR 1',
    type: 'GSTR_1_DOCUMENT',
    isDisabled: true,
  },
  {
    title: 'Additional GST Document',
    type: 'GST_DOCUMENTS',
    isDisabled: true,
  },
  {
    title: 'Sub vendor GSTR-3B',
    type: 'SUB_VENDOR_GSTR_3B',
    isDisabled: true,
  },
];

const ADDITIONAL_DOCS_LIST = {
  CASH_CHALLAN: 'Cash Challan',
  GSTR_2B_DOCUMENT: 'GST2B Document',
  GSTR_1_DOCUMENT: 'GSTR1 Document',
  GST_DOCUMENTS: 'GSTR3B Document',
  SUB_VENDOR_GSTR_3B: 'Sub vendor GSTR-3B',
};
const AGGREGATORS_STEP_LIST = {
  1: 'Business Details',
  2: 'Documentation',
  3: 'Seller Rating',
  4: 'Bank & Agreement Details',
};

const AGGREGATOR_DOCS_LIST = {
  GST_CERTIFICATE: 'GST Certificate',
  PERSONAL_PAN: 'Entity Pan',
  PAN_CARD: 'Owner Pan',
  AADHAAR_CARD: 'Aadhaar',
  MSME_DOCUMENT: 'MSME Certificate',
  GSTR_3B: 'GSTR-3B',
  CASH_CHALLAN: 'Cash Challan',
  GSTR_1_DOCUMENT: 'GSTR-1',
  GST_2A_DOCUMENT: 'GSTR2B',
  // GST_2A_DOCUMENT: 'GST-2A',
  ELECTRICITY_BILL: 'Electricity Bill',
  PROOF_OF_PREMISES: 'Proof of Premises',
  VKYC_REPORT: 'VKYC Report',
  OSV_REPORT: 'OSV Report',
  CUSTOMER_PHOTO: 'Customer Photo',
};

const AGGREGATOR_DOCS_KEY_VALUE_LIST = {
  'KYC DOCUMENT': ['GST_CERTIFICATE', 'PERSONAL_PAN', 'PAN_CARD', 'AADHAAR_CARD', 'MSME_DOCUMENT'],
  'GST DOCUMENTS': ['GSTR_3B', 'CASH_CHALLAN', 'GSTR_1_DOCUMENT', 'GST_2A_DOCUMENT'],
  Reports: ['VKYC_REPORT', 'OSV_REPORT'],
  'ADDITIONAL DOCUMENT': ['ELECTRICITY_BILL', 'PROOF_OF_PREMISES', 'CUSTOMER_PHOTO'],
};

const SEGMENT = [
  {
    status: 'Vertical Detail',
    title: 'Vertical, Vendor and Conditions Detail',
    toStatus: 'PLACED',
    date: null,
  },
  {
    status: 'Segmentation Detail',
    title: 'Segmentation Detail',
    toStatus: 'PLACED',
    date: null,
  },
];

export const PO_INVOICE_STATUS = {
  open: {
    id: 'open',
    name: 'Open',
    borderColor: '#FECDCA',
    backGroundColor: '#FEF3F2',
    textColor: '#B42318',
  },
  paid: {
    id: 'paid',
    name: 'Paid',
    borderColor: '#ABEFC6',
    backGroundColor: '#ECFDF3',
    textColor: '#067647',
  },
  overdue: {
    id: 'overdue',
    name: 'Overdue',
    borderColor: '#FECDCA',
    backGroundColor: '#FEF3F2',
    textColor: '#B42318',
  },
  void: {
    id: 'void',
    name: 'Void',
    borderColor: '#707070',
    backGroundColor: '#D9D9D9',
    textColor: '#707070',
  },
  partially_paid: {
    id: 'partially_paid',
    name: 'Partially Paid',
    borderColor: '#FEDF89',
    backGroundColor: '#FFFAEB',
    textColor: '#B54708',
  },
};

const RECALCULATION = [
  {
    id: 1,
    value: 'Weekly',
  },
  {
    id: 2,
    value: 'Monthly',
  },
  {
    id: 3,
    value: 'Quarterly',
  },
];

export const KYC_VERIFICATION_STATUS = {
  pending: {
    id: 'pending',
    name: 'Pending',
    borderColor: '#FECDCA',
    backGroundColor: '#FEF3F2',
    textColor: '#B42318',
  },
  initiated: {
    id: 'initiated',
    name: 'Initiated',
    borderColor: '#FEDF89',
    backGroundColor: '#FFFAEB',
    textColor: '#B54708',
  },
  completed: {
    id: 'completed',
    name: 'Completed',
    borderColor: '#ABEFC6',
    backGroundColor: '#ECFDF3',
    textColor: '#067647',
  },
};

export const VENDOR_TYPE = [
  { name: 'Seller', id: 'AGGREGATOR' },
  { name: 'Buyer', id: 'RECYCLER' },
];

export const UPDATE_TYPE = [
  { name: 'Communication', id: 'COMMUNICATION' },
  { name: 'Policy', id: 'POLICY' },
];

const ZOHO_PAYMENT_STATUS = {
  CLEARED: 'cleared',
  DEBIT_NOTE: 'debit note',
  INITIATED: 'initiated',
  PROCESSING: 'processing',
};

const DELIVERY_DETAILS_DOCUMENTS = {
  RECYCLER_WEIGHBRIDGE_BILL: ['RECYCLER_WEIGHBRIDGE_BILL'],
  QC_DONE: ['QC_DONE'],
  DEBIT_NOTE: ['DEBIT_NOTE'],
  LR_COPY: ['LR_COPY', 'LORRY_RECEIPT'],
  GRN_OTHERS: ['GRN'],
};

const RECYCLER_DELIVERY_DOCUMENTS = {
  RECYCLER_WEIGHBRIDGE_BILL: {
    title: 'Buyer Weighbridge',
    type: 'RECYCLER_WEIGHBRIDGE_BILL',
    status: 'MATERIAL_LOADED',
  },
  QC_DONE: {
    title: 'Buyer QC Report',
    type: 'QC_DONE',
    status: 'MATERIAL_LOADED',
  },
  DEBIT_NOTE: {
    title: 'Debit Note',
    type: 'DEBIT_NOTE',
    status: 'MATERIAL_LOADED',
  },
  LR_COPY: {
    title: 'Seller LR Copy',
    type: 'LR_COPY',
    status: 'MATERIAL_LOADED',
  },
  GRN_OTHERS: {
    title: 'GRN/Others',
    type: 'GRN',
    status: 'MATERIAL_LOADED',
  },
  LORRY_RECEIPT: {
    title: 'Transporter LR Copy',
    type: 'LORRY_RECEIPT',
    status: 'MATERIAL_LOADED',
  },
};
const DEBIT_NOTE_ZOHO_STATUS = [
  { id: 'approved', name: 'approved' },
  { id: 'closed', name: 'closed' },
  { id: 'draft', name: 'draft' },
  { id: 'open', name: 'open' },
  { id: 'overdue', name: 'overdue' },
  { id: 'paid', name: 'paid' },
  { id: 'partially_paid', name: 'partially paid' },
  { id: 'pending_approval', name: 'pending approval' },
  { id: 'sent', name: 'sent' },
  { id: 'void', name: 'void' },
];

const DEBIT_NOTE_ZOHO_STATUSES = {
  draft: {
    id: 'draft',
    name: 'Draft',
    colors: '#FFFAEB',
    textColor: '#B54708',
    borderColor: '#FEDF89',
  },
  approved: {
    id: 'approved',
    name: 'Approved',
    colors: '#ECFDF3',
    textColor: '#067647',
    borderColor: '#ABEFC6',
  },
  paid: {
    id: 'paid',
    name: 'paid',
    colors: '#ECFDF3',
    textColor: '#067647',
    borderColor: '#ABEFC6',
  },
  open: {
    id: 'open',
    name: 'Open',
    colors: '#FEF3F2',
    textColor: '#B42318',
    borderColor: '#FECDCA',
  },
  closed: {
    id: 'closed',
    name: 'Closed',
    colors: '#FDF2FA',
    textColor: '#C11574',
    borderColor: '#FCCEEE',
  },
  overdue: {
    id: 'overdue',
    name: 'Overdue',
    colors: '#FDF2FA',
    textColor: '#C11574',
    borderColor: '#FCCEEE',
  },
  sent: {
    id: 'sent',
    name: 'Sent',
    colors: '#EFF8FF',
    textColor: '#175CD3',
    borderColor: '#B2DDFF',
  },
  void: {
    id: 'void',
    name: 'Void',
    colors: '#EFF8FF',
    textColor: '#175CD3',
    borderColor: '#B2DDFF',
  },
  partially_paid: {
    id: 'partially_paid',
    name: 'Partially Paid',
    colors: '#FFFAEB',
    textColor: '#B54708',
    borderColor: '#FEDF89',
  },
  pending_approval: {
    id: 'pending_approval',
    name: 'Pending Approval',
    colors: '#FEF3F2',
    textColor: '#B42318',
    borderColor: '#FECDCA',
  },
};

const AUDIT_TRAIL_LEVEL_2_APPROVAL_KEY = 'in_process_level_02_approval';

const SHIPMENT_HOLD_REASONS = [
  { id: 0, label: 'Credit limit issue' },
  { id: 1, label: 'Vehicle number incorrect' },
  { id: 2, label: 'Quantity incorrect' },
  { id: 3, label: 'Date incorrect' },
  { id: 4, label: 'Address mismatch' },
  { id: 5, label: 'Distance issue' },
  { id: 6, label: 'Clear vehicle images' },
  { id: 7, label: 'Vehicle number not visible' },
  { id: 8, label: 'Tare weight incorrect' },
  { id: 9, label: 'Gross weight incorrect' },
  { id: 10, label: 'Net weight incorrect' },
  { id: 11, label: 'Weighment address incorrect' },
  { id: 12, label: 'Invoice date incorrect' },
  { id: 13, label: 'Description of goods incorrect' },
  { id: 14, label: 'Rate incorrect' },
  { id: 15, label: 'Invoice number incorrect' },
  { id: 16, label: 'Rapidue GSTIN and name incorrect' },
  { id: 17, label: 'Approval awaiting' },
  { id: 18, label: 'Other' },
];

const USER_UNAVAILABILITY_REASONS = [
  { id: 0, label: 'Break' },
  { id: 1, label: 'Power issue' },
  { id: 2, label: 'Network issue' },
  { id: 3, label: 'Personal work/reason' },
  { id: 4, label: 'Early logout' },
  { id: 5, label: 'Meeting' },
  { id: 6, label: 'Event/session' },
  { id: 7, label: 'Assets issue' },
  { id: 8, label: 'Other' },
];

const TRUCK_DETAILS_ERROR_MESSAGES = {
  CAPACITY: 'Loading capacity is insufficient for the shipment quantity',
  RC_STATUS: ' The vehicles RC status is inactive',
  INSURANCE_EXPIRED: 'The vehicles insurance has expired',
  VEHICLE_FITNESS_EXPIRED: 'The vehicles fitness certificate has expired',
};

const AUDIT_LOG_TYPES = {
  SYSTEM_USER: 'SYSTEM_USER',
  AUTHORITY: 'AUTHORITY',
};

const GRN_OTHERS_DOC_TYPE_LIST = ['GRN', 'DC_ACKNOWLEDGEMENT'];

const EXISTING_SECTIONS = ['VEHICLE_IMAGES', 'WEIGHBRIDGE_IMAGES', 'SALES_IMAGES'];

const EXISTING_DOC_TYPES = [
  'TRUCK_FRONT_PHOTO',
  'TRUCK_BACK_PHOTO',
  'EMPTY_WEIGHTBRIDGE',
  'FULL_WEIGHBRIDGE',
  'SELLER_INVOICE',
  'SELLER_EWAY_BILL',
  'LR_COPY',
];
const EXISTING_DATA_POINTS = [
  'vehicleNumber',
  'geoTagVerified',
  'sellerWeighbridgeNo',
  'finalPickedQuantity',
  'weighmentAddress',
  'sellerEwayBillNo',
  'pickupAddress',
  'sellerInvoiceNo',
  'sellerInvoiceDate',
  'numberOfBales',
  'rateInAggInvoice',
  'weight',
  'address',
  'createdDate',
  'shipmentId',
  'shipmentItemsDTO',
  'mpShipmentItems',
  'sellerPrice',
  'dispatchedQuantity',
  'invoiceNumber',
];

const DEFAULT_SHIPMENT_KEYS = [
  'VEHICLE_IMAGES',
  'WEIGHBRIDGE_IMAGES',
  'SALES_IMAGES',
  'vehicleNumber',
  'geoTagVerified',
  'sellerWeighbridgeNo',
  'finalPickedQuantity',
  'weighmentAddress',
  'sellerEwayBillNo',
  'pickupAddress',
  'sellerInvoiceNo',
  'sellerInvoiceDate',
  'numberOfBales',
  'TRUCK_FRONT_PHOTO',
  'TRUCK_BACK_PHOTO',
  'LR_COPY',
  'EMPTY_WEIGHTBRIDGE',
  'FULL_WEIGHBRIDGE',
  'SELLER_INVOICE',
  'SELLER_EWAY_BILL',
  'dispatchedQuantity',
  'sellerPrice',
];

const DATAPOINTS_RELATED_TO_E_WAY_BILL_DOC = [
  'sellerEwayBillNo',
  'sellerInvoiceDate',
  'sellerInvoiceNo',
  'dispatchedQuantity',
  'sellerPrice',
];

const DOCUMENT_SECTION_MASTER_ERROR_MESSAGES = {
  EXISTING_KEYS: 'name conflicts with existing keys. Please choose a different name.',
  DUPLICATE_SECTION: 'Section name already exists. Please choose a different name.',
  DUPLICATE_DOCUMENT: 'Document name already exists. Please choose a different name.',
  DUPLICATE_DATAPOINT: 'Data Point name already exists. Please choose a different name.',
};

const INPUT_MASK_QC = {
  qualityParam: {
    prefix: '',
    allowDecimal: true,
    decimalLimit: 3,
    integerLimit: 3,
    suffix: '', // This will put the dollar sign at the end, with a space.
  },
  quantityParam: {
    prefix: '',
    allowDecimal: true,
    decimalLimit: 3,
    integerLimit: 9,
    suffix: '', // This will put the dollar sign at the end, with a space.
  },
};
export {
  ORDER_STATUS,
  AUTHORITY,
  LISTING_ORDER_REJECT_REASONS,
  PURCHASE_ORDER_REJECT_REASONS,
  SALE_ORDER_REJECT_REASONS,
  INPUT_MASK,
  CASH_CHALLAN_DURATION,
  ASSESSMENT_STATUS,
  SERVICE,
  PAGE_CONFIG,
  ROLES,
  MOBILE_NUMBER_REGEX,
  GEO_PREFERENCE_LIST,
  PAYMENT_MODES_LIST,
  PAYMENT_STATUS,
  SELLER_LIST,
  AGGREGATOR_DOCUMENTS_SALEORDER,
  AGGREGATOR_DOCUMENTS,
  RECYCLER_DOCUMENTS,
  TRANSPORTER_DOCUMENTS_ACCOUNTS,
  TRANSPORTER_DOCUMENTS,
  DISPATCH_DOCUMENTS,
  ACCOUNTS_DOCUMENTS,
  DOC_PREFIX,
  QCParameters,
  EMAIL_REGEX,
  ADMIN_VERIFICATION_DOCS,
  TRANSPORTER_PAYMENT_INVOICE,
  ALL_DOCS,
  DOC_TYPES,
  PO_STATUS,
  PO_DOCS,
  EPR_DOC,
  ALL_PO_DOCS,
  LISTS_STATUS,
  KAM_LISTS_STATUS,
  ROLE_PRIORITY,
  gstCheckDate,
  SALESORDER_STATUS,
  QUOTES_STATUS,
  MAX_CHARACTERS_LENGTH,
  RECYCLER_SUPER_ROLE,
  MAX_EIGHT_NUMBER_REGEX,
  ALPHA_NUMERIC,
  MATERIAL_REASONS,
  SALE_ORDER_TRUCK_DELAY_REASONS,
  AGGREGATOR_ORDER_SCHEDULED_REASONS,
  GST_FILING_STATUS,
  BUYER_DEBITNOTE_REASON_LIST,
  PAYMENT_AGG_TYPE_LIST,
  PAYMENT_TRANS_TYPE_LIST,
  AGGREGATOR_STATUS,
  AGGREGATOR_TYPES,
  PAYMENT_TERM_EVENT,
  RECONCILATION_STATUS,
  SALE_ORDER_DOCUMENT_STATUS,
  INCORRECT_DOCUMENT_TYPES,
  CAMPAIGN_STATUS,
  CAMPAIGN_SELLER_STATUS,
  CAMPAIGN_SELLER_TYPE,
  CAMPAIGN_AGGREGATOR_STATUS,
  NOTE_BUYER_DIFF_NOTE_SELLER,
  KYC_STATUS,
  CAMPAIGN_STATUS_EVENTS,
  CAMPAIGN_CONDITIONS,
  CAMPAIGN_BONUS_DROPDOWN,
  CAMPAIGN_PAYMENT_DROPDOWN,
  CAMPAIGN_PAYMENT_WHEN_OPTIONS,
  CAMPAIGN_BONUS_INDEX_DROPDOWN,
  CAMPAIGN_WORKFLOW_STATUS,
  CAMPAIGN_OFFERPRICE_LIST,
  ACCOUNTS_DOCUMENTS_SELLER,
  allState,
  allCity,
  allItems,
  TRACKING_STATUS,
  TRIP_STATUS,
  ROLE_MATERIAL,
  PAYMENT_MATERIAL_STATUS,
  PAYMENT_GST_STATUS,
  TRACK_NOW_STATUS,
  TRACK_MANUAL_STATUS,
  TRACKING_VIEW,
  SOURCE_OF_DOCUMENTS,
  BankStatus,
  BANK_DOCUMENT_REJECTION_REASON,
  USER_UPLOADING_QC_REPORT,
  DOCUMENTS_SOURCE,
  AGGREGATOR_RATING_STATUS,
  LIABILITY_OPTIONS,
  AADHAR_STATUS,
  COMPLIANCE_STATUS,
  OSV_STATUS,
  LEAD_STATUS,
  HSN_OPTIONS,
  DEBIT_NOTE_REASONS,
  USER_UPLOADING_NOTE_TO_BUYER,
  TOAST_TYPES,
  AGGREGATOR_CREATING_EWAY_BILL,
  SHIPMENTS_URL,
  LISTING_ORDER_URL,
  PURCHASE_ORDER_URL,
  SALE_ORDERS_URL,
  AGGREGATOR_DETAILS_URL,
  ListingDayStatus,
  ZOHO_STATUS,
  CATEGORY_HEAD,
  CATEGORY_HEAD_STAGE,
  USER_UPLOADING_DEBIT_NOTE,
  ACTIVITY_EVENTS,
  LISTING_STATUS,
  TOLERANCE_OPTIONS,
  TOLERANCE_OPTIONS_VIEW,
  RECYCLER_REASONS_FOR_UNAVAILABLE_PRICE,
  AGGREGATOR_REASONS_FOR_UNAVAILABLE_PRICE,
  PURCHASE_ORDER_STATUSES,
  AGGREGATOR_DEPARTMENTS,
  GST_KYC_STATUS,
  AGREEMENT_TYPE,
  APPLEAD_DOCUMENT_TYPE,
  ALL_VALID_DOC_TYPES,
  VALIDATION_DOCUMENT_MAPPER,
  PLANT_MACHINE_MAPPER,
  KYC_VERIFICATION_MAPPER,
  ADDITIONAL_DOCUMENTS_MAPPER,
  ATTRIBUTE_DATA_TYPES,
  QC_ATTRIBUTE_DATA_TYPES,
  LISTING_DOC_LIST,
  VALID_DOCUMENT_TYPES,
  BANK_VERIFICATION_STATUS_ERROR_CODE,
  ACCOUNTS_DOCUMENTS_SELLER_MANDATORY,
  STATUS_TRACKNOW,
  STATUS_FASTAG,
  ADMIN_EDIT_STATUS,
  SALE_ORDER_DETAILS_LIST,
  COMMENTS_TAB_LIST,
  AGGR_COMMENTS_TEXT,
  AGGR_PAYMENT_TABS,
  ADDITIONAL_DOCS_GST1,
  ADDITIONAL_DOCS_LIST,
  AGGREGATORS_STEP_LIST,
  AGGREGATOR_DOCS_LIST,
  SEGMENT,
  RECALCULATION,
  AGGREGATOR_DOCS_KEY_VALUE_LIST,
  ZOHO_PAYMENT_STATUS,
  DELIVERY_DETAILS_DOCUMENTS,
  RECYCLER_DELIVERY_DOCUMENTS,
  DEBIT_NOTE_ZOHO_STATUS,
  DEBIT_NOTE_ZOHO_STATUSES,
  AUDIT_TRAIL_LEVEL_2_APPROVAL_KEY,
  SHIPMENT_HOLD_REASONS,
  USER_UNAVAILABILITY_REASONS,
  TRUCK_DETAILS_ERROR_MESSAGES,
  AUDIT_LOG_TYPES,
  GRN_OTHERS_DOC_TYPE_LIST,
  DEFAULT_SHIPMENT_KEYS,
  DATAPOINTS_RELATED_TO_E_WAY_BILL_DOC,
  DOCUMENT_SECTION_MASTER_ERROR_MESSAGES,
  EXISTING_DOC_TYPES,
  EXISTING_DATA_POINTS,
  EXISTING_SECTIONS,
  INPUT_MASK_QC,
};
